import React, {Component} from 'react';
import {Tab, Tabs} from 'react-materialize';
import Cookies from "universal-cookie";
import OrganizationForOrgUsersInfoEdit from "../OrganizationForOrgUsersInfoEdit/OrganizationForOrgUsersInfoEdit";
import OrganizationForOrgUsersInfo from "../OrganizationForOrgUsersInfo/OrganizationForOrgUsersInfo";
import OrganizationReportsList from "../OrganizationReportsList/OrganizationReportsList";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();

class OrganizationForOrgUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token')),
            organization: {},
            message: {},
            fireRedirect: false,
            urlParam: ''
        };
    }

    componentDidMount() {
        fetch(`/server/organizations/${this.props.id}`)
            .then(res => res.json())
            .then(organization =>
                this.setState({organization}));
    }

    render() {
        return (
            <div>
                <Tabs className="tab-demo tabs-fixed-width z-depth-1">
                    <Tab className="" title="Informations" active>
                        {(this.state.cookie && this.state.cookie.isAdmin === 1) ?
                            <OrganizationForOrgUsersInfoEdit id={this.props.id}/>
                            :
                            <OrganizationForOrgUsersInfo id={this.props.id}/>
                        }
                    </Tab>
                    <Tab className="" title="Rapports">
                        <OrganizationReportsList id={this.props.id}/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default OrganizationForOrgUsers;
