import React, {Component} from 'react';
import './Report.css';
import ReportEdit from "../../components/ReportEdit/ReportEdit";
import Cookies from "universal-cookie";
import {createBrowserHistory} from "history";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();
const history = createBrowserHistory({
    forceRefresh: true
});

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: {},
            cookie: jwt.decode(cookies.get('token')),
        }

        const {id} = this.props.match.params;
        fetch(`/server/reports/${id}`)
            .then(res => res.json())
            .then(report => {
                this.setState({report});
            })
            .then(_ => {
                if (this.state.cookie.isAdmin !== 1)
                    fetch(`/server/organizations/${this.state.report.fk_organization}/organizationPermission/${this.state.cookie.pk_auditor}`)
                        .then(res => res.json())
                        .then(permission => {
                            console.log(`Permissions: ${permission.isAllowed === true}`)
                            /*if (!permission.isAllowed)
                                history.push('/organizations');*/
                        });
            })
    }

    render() {
        return (
            <div>
                <ReportEdit id={this.props.match.params}/>
            </div>
        );
    }
}

export default Report;
