import React, {Component} from 'react';
import './ConclusionEdit.css';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    TextInput,
    Button,
    Textarea,
    DatePicker, Table, Checkbox
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class ConclusionEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conclusion: {},
            conclusionComments: [],
            conclusionComments_Conclusion: [],
            conclusionCompliances: [],
            conclusionCompliances_Conclusion: [],
            conclusionNoncompliances: [],
            conclusionNoncompliances_Conclusion: [],
            conclusionReviews: [],
            conclusionReviews_Conclusion: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getConclusions();
    }

    getConclusions() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}/conclusion`)
            .then(res => res.json())
            .then(conclusion => {
                this.setState({conclusion});
                this.setState({conclusionComments: conclusion.conclusionComments});
                this.setState({conclusionCompliances: conclusion.conclusionCompliances});
                this.setState({conclusionNoncompliances: conclusion.conclusionNoncompliances});
                this.setState({conclusionReviews: conclusion.conclusionReviews});
            })
            .then(() => {
                fetch(`/server/reports/conclusion/${this.state.conclusion.pk_conclusion}/conclusionComments`)
                    .then(res => res.json())
                    .then((conclusionComments_Conclusion) => this.setState({conclusionComments_Conclusion}))
            })
            .then(() => {
                fetch(`/server/reports/conclusion/${this.state.conclusion.pk_conclusion}/conclusionReviews`)
                    .then(res => res.json())
                    .then((conclusionReviews_Conclusion) => this.setState({conclusionReviews_Conclusion}))
            })
            .then(() => {
                fetch(`/server/reports/conclusion/${this.state.conclusion.pk_conclusion}/conclusionCompliance`)
                    .then(res => res.json())
                    .then((conclusionCompliances_Conclusion) => this.setState({conclusionCompliances_Conclusion}))
            })
            .then(() => {
                fetch(`/server/reports/conclusion/${this.state.conclusion.pk_conclusion}/conclusionNoncompliance`)
                    .then(res => res.json())
                    .then((conclusionNoncompliances_Conclusion) => this.setState({conclusionNoncompliances_Conclusion}))
            });
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(6);
        }
    }

    changeNextAuditExaminedElement(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            conclusion: {
                ...this.state.conclusion,
                nextAuditExaminedElement: event.target.value
            }
        });
    }

    changeNextAuditFirstDaysDate(date) {
        this.checkDataAlreadyChanged();

        const newDate = new Date(date);
        this.setState({
            conclusion: {
                ...this.state.conclusion,
                nextAuditFirstDaysDate: newDate
            }
        });
    }

    changeLocationDateManager(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            conclusion: {
                ...this.state.conclusion,
                locationDateManager: event.target.value
            }
        });
    }

    changeYesReviews(event, i) {
        this.checkDataAlreadyChanged();
        console.log(event.target.checked);
        const newYes = event.target.checked;
        this.setState(state => {
            const conclusionReviews_Conclusion = state.conclusionReviews_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        yes: newYes
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionReviews_Conclusion
            };
        });
    }

    changeCommentReviews(event, i) {
        this.checkDataAlreadyChanged();

        const newComment = event.target.value;
        this.setState(state => {
            const conclusionReviews_Conclusion = state.conclusionReviews_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        comment: newComment
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionReviews_Conclusion
            };
        });
    }

    changeYesComments(event, i) {
        this.checkDataAlreadyChanged();

        const newYes = event.target.checked;
        this.setState(state => {
            const conclusionComments_Conclusion = state.conclusionComments_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        yes: newYes
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionComments_Conclusion
            };
        });
    }

    changeCommentComments(event, i) {
        this.checkDataAlreadyChanged();

        const newComment = event.target.value;
        this.setState(state => {
            const conclusionComments_Conclusion = state.conclusionComments_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        comment: newComment
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionComments_Conclusion
            };
        });
    }

    changeYesCompliance(event, i) {
        this.checkDataAlreadyChanged();

        const newYes = event.target.checked;
        this.setState(state => {
            const conclusionCompliances_Conclusion = state.conclusionCompliances_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        yes: newYes
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionCompliances_Conclusion
            };
        });
    }

    changeCommentCompliance(event, i) {
        this.checkDataAlreadyChanged();

        const newComment = event.target.value;
        this.setState(state => {
            const conclusionCompliances_Conclusion = state.conclusionCompliances_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        comment: newComment
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionCompliances_Conclusion
            };
        });
    }

    changeYesNoncompliance(event, i) {
        this.checkDataAlreadyChanged();

        const newYes = event.target.checked;
        this.setState(state => {
            const conclusionNoncompliances_Conclusion = state.conclusionNoncompliances_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        yes: newYes
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionNoncompliances_Conclusion
            };
        });
    }

    changeCommentNoncompliance(event, i) {
        this.checkDataAlreadyChanged();

        const newComment = event.target.value;
        this.setState(state => {
            const conclusionNoncompliances_Conclusion = state.conclusionNoncompliances_Conclusion.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        comment: newComment
                    };
                } else {
                    return item;
                }
            });

            return {
                conclusionNoncompliances_Conclusion
            };
        });
    }

    updateConclusion() {
        const {id} = this.props.id;
        fetch(`/server/reports/update/${id}/conclusion/`, {
            method: 'POST',
            body: JSON.stringify(this.state.conclusion),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });

        fetch(`/server/reports/update/conclusion/${this.state.conclusion.pk_conclusion}/comment`, {
            method: 'POST',
            body: JSON.stringify(this.state.conclusionComments_Conclusion),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/conclusion/${this.state.conclusion.pk_conclusion}/review`, {
            method: 'POST',
            body: JSON.stringify(this.state.conclusionReviews_Conclusion),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/conclusion/${this.state.conclusion.pk_conclusion}/compliance`, {
            method: 'POST',
            body: JSON.stringify(this.state.conclusionCompliances_Conclusion),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/conclusion/${this.state.conclusion.pk_conclusion}/noncompliance`, {
            method: 'POST',
            body: JSON.stringify(this.state.conclusionNoncompliances_Conclusion),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    }

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getConclusions();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Collection className="z-depth-1">
                            <CollectionItem>
                                <Textarea s={12} m={12} value={this.state.conclusion.nextAuditExaminedElement}
                                           label="Éléments à examiner durant le prochain audit" validate
                                           onChange={(event) => this.changeNextAuditExaminedElement(event)}/>
                                <DatePicker s={12} m={12}
                                            options={{
                                                defaultDate: new Date(this.state.conclusion.nextAuditFirstDaysDate),
                                                setDefaultDate: true,
                                                format: 'dd/mm/yyyy'
                                            }}
                                            value={new Date(this.state.conclusion.nextAuditFirstDaysDate).toLocaleDateString()}
                                            label="Date du premier jour du prochain audit"
                                            icon="date_range"
                                            onChange={(event) => this.changeNextAuditFirstDaysDate(event)}/>
                                <Textarea s={12} m={12} value={this.state.conclusion.locationDateManager}
                                          label="Lieu, date, nom du responsable d'audit" validate
                                          onChange={(event) => this.changeLocationDateManager(event)}/>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th className="table-title">
                                            Revue du système de management
                                        </th>
                                        <th className="table-yes">
                                            Oui
                                        </th>
                                        <th className="table-comment">
                                            Remarque
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.conclusionReviews_Conclusion.map((comment, i) =>
                                        <tr>
                                            <td className="table-title">{this.state.conclusionReviews[i].title}</td>
                                            <td className="table-yes">
                                                <Checkbox checked={comment.yes}
                                                          onChange={(event) => this.changeYesReviews(event, i)}/>
                                            </td>
                                            <td className="table-comment">
                                                <Textarea value={comment.comment}
                                                          onChange={(event) => this.changeCommentReviews(event, i)}
                                                          s={12} m={12}/></td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th className="table-title">
                                            Commentaire sur l’utilisation de la marque
                                        </th>
                                        <th className="table-yes">
                                            Oui
                                        </th>
                                        <th className="table-comment">
                                            Remarque
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.conclusionComments_Conclusion.map((comment, i) =>
                                        <tr>
                                            <td className="table-title">{this.state.conclusionComments[i].title}</td>
                                            <td className="table-yes">
                                                <Checkbox checked={comment.yes}
                                                          onChange={(event) => this.changeYesComments(event, i)}/>
                                            </td>
                                            <td className="table-comment">
                                                <Textarea value={comment.comment}
                                                          onChange={(event) => this.changeCommentComments(event, i)}
                                                          s={12} m={12}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th className="table-title">
                                            Conformité du Système
                                        </th>
                                        <th className="table-yes">
                                            Oui
                                        </th>
                                        <th className="table-comment">
                                            Remarque
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.conclusionCompliances_Conclusion.map((comment, i) =>
                                        <tr>
                                            <td className="table-title">{this.state.conclusionCompliances[i].title}</td>
                                            <td className="table-yes">
                                                <Checkbox checked={comment.yes}
                                                          onChange={(event) => this.changeYesCompliance(event, i)}/>
                                            </td>
                                            <td className="table-comment">
                                                <Textarea value={comment.comment}
                                                          onChange={(event) => this.changeCommentCompliance(event, i)}
                                                          s={12} m={12}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th className="table-title">
                                            Non-conformité du Système
                                        </th>
                                        <th className="table-yes">
                                            Oui
                                        </th>
                                        <th className="table-comment">
                                            Remarque
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.conclusionNoncompliances_Conclusion.map((comment, i) =>
                                        <tr>
                                            <td className="table-title">{this.state.conclusionNoncompliances[i].title}</td>
                                            <td className="table-yes">
                                                <Checkbox checked={comment.yes}
                                                          onChange={(event) => this.changeYesNoncompliance(event, i)}/>
                                            </td>
                                            <td className="table-comment">
                                                <Textarea value={comment.comment}
                                                          onChange={(event) => this.changeCommentNoncompliance(event, i)}
                                                          s={12} m={12}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </CollectionItem>
                        </Collection>
                    </Col>
                </Row>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateConclusion()}/>
                    }

                </Button>
            </div>
        );
    }
}

export default ConclusionEdit;
