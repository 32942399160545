import React, {Component} from 'react';
import {Button, Checkbox, Col, Card, Row, Select, TextInput} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class OrganizationUserInfoEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            languages: []
        };
    }

    componentDidMount() {
        this.getUser();
        fetch('/server/languages')
            .then(res => res.json())
            .then(languages => {
                this.setState({languages});
            });
    }

    getUser() {
        const {id} = this.props.id;
        fetch(`/server/organizationUser/${id}`)
            .then(res => res.json())
            .then(user =>
                this.setState({user}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
        }
    }

    changeLastName(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            user: {
                ...this.state.user,
                lastName: event.target.value
            }
        });
    };

    changeFirstName(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            user: {
                ...this.state.user,
                firstName: event.target.value
            }
        });
    };

    changeEmail(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            user: {
                ...this.state.user,
                email: event.target.value
            }
        });
    };

    changeIsAdmin(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            user: {
                ...this.state.user,
                isAdmin: event.target.checked
            }
        });
    }

    changeLanguage(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            user: {
                ...this.state.user,
                fk_language: event.target.value
            }
        });
    }

    updateUser() {
        const {id} = this.props.id;
        fetch(`/server/organizationUser/update/${id}`, {
            method: 'POST',
            body: JSON.stringify(this.state.user),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });

        this.setState({
            dataChanged: false
        });
    };

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.getUser();
    }

    render() {
        return (
            <div>
                <Card>
                    <TextInput s={12} m={12} value={this.state.user.lastName} label="Nom"
                               onChange={(event) => this.changeLastName(event)}/>
                    <TextInput s={12} m={12} value={this.state.user.firstName} label="Prénom"
                               onChange={(event) => this.changeFirstName(event)}/>
                    < TextInput s={12} m={12} value={this.state.user.email} label="Email" email validate
                                onChange={(event) => this.changeEmail(event)}/>
                    <Row>
                        <Col>
                            <Checkbox checked={this.state.user.isAdmin} label="Administrateur"
                                      onChange={(event) => this.changeIsAdmin(event)}/>
                        </Col>
                    </Row>
                    <Select className="select-collection" label="Langue"
                            value={this.state.user.fk_language} s={12} m={12}
                            onChange={(event) => this.changeLanguage(event)}>
                        {this.state.languages.map((language, i) =>
                            <option value={language.pk_language}>
                                {language.language}
                            </option>
                        )}
                    </Select>
                </Card>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateUser()}/>
                    }
                </Button>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default OrganizationUserInfoEdit;
