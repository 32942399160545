import React, {Component} from 'react';
import './AddReport.css';
import NewReportForm from "../../components/NewReportForm/NewReportForm";

class AddReport extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.location.state.id)
    }

    render() {
        return (
            <div>
                <NewReportForm id={this.props.location.state.id}/>
            </div>
        );
    }
}

export default AddReport;
