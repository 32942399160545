import React, {Component} from 'react';
import './Home.css';
import Cookies from "universal-cookie";
import OrganizationForOrgUsers from "../../components/OrganizationForOrgUsers/OrganizationForOrgUsers";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token'))
        };
    }

    render() {
        return (
            <div>
                {this.state.cookie && this.state.cookie.fk_organization &&
                <OrganizationForOrgUsers id={this.state.cookie.fk_organization} />
                }

            </div>
        );
    }
}

export default Home;
