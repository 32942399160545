import React, {Component} from 'react';
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";
import NewOrganizationUserForm from "../../components/NewOrganizationUserForm/NewOrganizationUserForm";

const jwt = require('jsonwebtoken');
const history = createBrowserHistory({
    forceRefresh: true
});
const cookies = new Cookies();

class AddOrganizationUser extends Component {
    render() {
        /*if (jwt.decode(cookies.get('token')).isAdmin !== 1)
            history.push('/');*/

        return (
            <div>
                <NewOrganizationUserForm id={this.props.match.params}/>
            </div>
        );
    }
}

export default AddOrganizationUser;
