import React, {Component} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Card, Col, Row, TextInput, Select, Checkbox} from 'react-materialize';

class NewAuditorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditor: {
                fk_language: 1
            },
            languages: [],
            message: {}
        }
    }

    componentDidMount() {
        fetch('/server/languages')
            .then(res => res.json())
            .then(languages => {
                this.setState({languages});
            });
    }

    changeLastName(event) {
        this.setState({
            auditor: {
                ...this.state.auditor,
                lastName: event.target.value
            }
        });
    };

    changeFirstName(event) {
        this.setState({
            auditor: {
                ...this.state.auditor,
                firstName: event.target.value
            }
        });
    };

    changeEmail(event) {
        this.setState({
            auditor: {
                ...this.state.auditor,
                email: event.target.value
            }
        });
    };

    changePhone(event) {
        this.setState({
            auditor: {
                ...this.state.auditor,
                phone: event.target.value
            }
        });
    };

    changeIsAdmin(event) {
        console.log(event.target.checked);
        this.setState({
            auditor: {
                ...this.state.auditor,
                isAdmin: event.target.checked
            }
        });
        console.log(this.state.auditor);
    }

    changeLanguage(event) {
        this.setState({
            auditor: {
                ...this.state.auditor,
                fk_language: event.target.value
            }
        });
    }

    addAuditor() {
        fetch('/server/auditors/add', {
            method: 'PUT',
            body: JSON.stringify(this.state.auditor),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.created) {
                    this.setState({
                        auditor: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    };

    render() {
        return (
            <div>
                <Card>
                    <TextInput label="Last Name" value={this.state.auditor.lastName} validate required
                               onChange={(event) => this.changeLastName(event)}/>
                    <TextInput label="First Name" value={this.state.auditor.firstName} validate required
                               onChange={(event) => this.changeFirstName(event)}/>
                    < TextInput label="Email" value={this.state.auditor.email} email validate required
                                onChange={(event) => this.changeEmail(event)}/>
                    <TextInput label="Phone" value={this.state.auditor.phone} validate required
                               onChange={(event) => this.changePhone(event)}/>
                    <Row>
                        <Col>
                            <Checkbox checked={this.state.auditor.isAdmin} label="Administrateur"
                                      onChange={(event) => this.changeIsAdmin(event)}/>
                        </Col>
                    </Row>
                    <Select className="select-collection" label="Langue"
                            value={this.state.auditor.fk_language} s={12} m={12}
                            onChange={(event) => this.changeLanguage(event)}>
                        {this.state.languages.map((language, i) =>
                            <option value={language.pk_language}>
                                {language.language}
                            </option>
                        )}
                    </Select>
                    <Button
                        floating
                        fab
                        icon="save"
                        className="green"
                        large
                        wave="light"
                        tooltip="Ajouter"
                        tooltipOptions={{position: 'left'}}
                        onClick={() => this.addAuditor()}
                    >
                    </Button>
                </Card>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default NewAuditorForm;
