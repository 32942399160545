import React, {Component} from 'react';
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";
import OrganizationUserInfoEdit from "../../components/OrganizationUserInfoEdit/OrganizationUserInfoEdit";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();
const history = createBrowserHistory({
    forceRefresh: true
});

class OrganizationUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token')),
            permission: {}
        }
    }

    componentDidMount() {
        /*if (this.state.cookie.isAdmin !== 1)
            history.push('/');*/
    }

    render() {
        return (
            <div>
                <OrganizationUserInfoEdit id={this.props.match.params}/>
            </div>
        );
    }
}

export default OrganizationUser;
