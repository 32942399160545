import React, {Component} from 'react';
import './Organization.css';
import OrganizationRW from "../../components/OrganizationRW/OrganizationRW";
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";
import OrganizationR from "../../components/OrganizationR/OrganizationR";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();
const history = createBrowserHistory({
    forceRefresh: true
});

class Organization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token')),
            permission: {}
        }
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        if (this.state.cookie.isAdmin !== 1)
            fetch(`/server/organizations/${id}/organizationPermission/${this.state.cookie.pk_auditor}`)
                .then(res => res.json())
                .then(permission => {
                    if (!permission.isAllowed)
                        history.push('/organizations');
                    this.setState({permission})
                });
    }

    render() {
        return (
            <div>
                {this.state.permission.fk_permission && this.state.permission.fk_permission === 1 &&
                <OrganizationR id={this.props.match.params}/>
                }
                {((this.state.cookie.isAdmin === 1) || (this.state.permission.fk_permission && this.state.permission.fk_permission === 2)) &&
                <OrganizationRW id={this.props.match.params}/>
                }
            </div>
        );
    }
}

export default Organization;
