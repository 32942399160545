import React, {Component} from 'react';
import SettingsEdit from "../../components/SettingsEdit/SettingsEdit";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <SettingsEdit/>
            </div>
        );
    }
}

export default Settings;
