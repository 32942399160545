import React, {Component} from 'react';
import {Row, Col, Collection, CollectionItem, Card, Button, Icon, Modal} from 'react-materialize';

class ChecklistsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checklists: []
        };
    }

    componentDidMount() {
        this.getChecklists();
    }

    getChecklists() {
        fetch('/server/checklists')
            .then(res => res.json())
            .then(checklists =>
                this.setState({checklists}));
    }

    deleteChecklist(pk_checklist) {
        fetch(`/server/checklists/delete/${pk_checklist}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getChecklists();
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Card>
                            <Collection>
                                {this.state.checklists.map(checklist =>
                                    <CollectionItem className="avatar" key={checklist.pk_checklist}>
                                        <a href={`/Checklist/${checklist.pk_checklist}`}><span
                                            className="title">{checklist.name}</span></a>
                                        <Modal trigger={<Button className="secondary-content red">
                                            <Icon className="white-text">delete</Icon>
                                        </Button>}>
                                            <div className="center-align">
                                                <h5>Voulez-vous vraiment supprimer cette checklist ?</h5>
                                                <p className="red-text"><Icon>warning</Icon> Cette action supprimera
                                                    également cette checklist de tous les rapports</p>
                                                <Button className="green center-align btn-modal"
                                                        onClick={() => this.deleteChecklist(checklist.pk_checklist)}>Confirmer</Button>
                                                <Button className="red center-align btn-modal"
                                                        modal="close">Annuler</Button>
                                            </div>
                                        </Modal>
                                    </CollectionItem>
                                )}

                            </Collection>
                        </Card>
                    </Col>

                </Row>

            </div>
        );
    }
}

export default ChecklistsList;
