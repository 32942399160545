import React, {Component} from 'react';
import {Button, Card, TextInput} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class NewChecklistForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checklist: {}
        }
    }

    componentDidMount() {
    }

    changeName(event) {
        this.setState({
            checklist: {
                ...this.state.checklist,
                name: event.target.value
            }
        });
    };

    addChecklist() {
        fetch('/server/checklists/add', {
            method: 'PUT',
            body: JSON.stringify(this.state.checklist),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.created) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    };

    render() {
        return (
            <div>
                <Card>
                    <TextInput label="Checklist Name" value={this.state.checklist.name}
                               onChange={(event) => this.changeName(event)} required/>
                    <Button
                        floating
                        fab
                        icon="save"
                        className="green"
                        large
                        wave="light"
                        tooltip="Ajouter"
                        tooltipOptions={{position: 'left'}}
                        onClick={() => this.addChecklist()}
                    >
                    </Button>
                </Card>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default NewChecklistForm;
