import React, {Component} from 'react';
import {Tabs, Tab} from 'react-materialize';
import OrganizationInfoEdit from "../OrganizationInfoEdit/OrganizationInfoEdit";
import OrganizationReportsListEdit from "../OrganizationReportsListEdit/OrganizationReportsListEdit";
import OrganizationUsersList from "../OrganizationUsersList/OrganizationUsersList";


class OrganizationRW extends Component {
    render() {

        return (
            <div>
                <Tabs className="tab-demo tabs-fixed-width z-depth-1"
                      options={{swipeable: false, responsiveThreshold: 1000}}>
                    <Tab className="" title="Profil de l'organisation" active>
                        <OrganizationInfoEdit id={this.props.id}/>
                    </Tab>
                    <Tab className="" title="Rapports d'audit">
                        <OrganizationReportsListEdit id={this.props.id}/>
                    </Tab>
                    <Tab className="" title="Contacts de l'organisation">
                        <OrganizationUsersList id={this.props.id}/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default OrganizationRW;
