import React, {Component} from 'react';
import {Col, Row, Card} from 'react-materialize';
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();

class OrganizationForOrgUsersInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token')),
            organization: {},
            message: {},
            fireRedirect: false,
            urlParam: ''
        };
    }

    componentDidMount() {
        fetch(`/server/organizations/${this.props.id}`)
            .then(res => res.json())
            .then(organization =>
                this.setState({organization}));
    }

    render() {
        return (
            <div>
                <Card>
                    <h5><u>{this.state.organization.name}</u></h5>
                    <Row>
                        <Col s={3}>
                            <h6><strong>Nom de la personne de contact : </strong>
                                {this.state.organization.contactFirstName} {this.state.organization.contactLastName}
                            </h6>
                        </Col>
                        <Col s={3}>
                            <h6><strong>Email : </strong>{this.state.organization.email}</h6>
                        </Col>
                        <Col s={3}>
                            <h6><strong>Téléphone : </strong>{this.state.organization.phone}</h6>
                        </Col>
                        <Col s={3}>
                            <h6><strong>Site Web : </strong>{this.state.organization.website}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={3}>
                            <h6><strong>Nom du siège de l'organisation : </strong>
                                {this.state.organization.headquartersName}
                            </h6>
                        </Col>
                        <Col s={3}>
                            <h6><strong>NP du siège : </strong>
                                {this.state.organization.headquartersNP}
                            </h6>
                        </Col>
                        <Col s={3}>
                            <h6><strong>Adresse du Siège : </strong>
                                {this.state.organization.headquartersAddress}
                            </h6>
                        </Col>
                        <Col s={3}>
                            <h6><strong>Ville du Siège : </strong>
                                {this.state.organization.headquartersLocation}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                            <h6><strong>Branches / Etablissements secondaires / Processus externalisés
                                : </strong>{this.state.organization.branches}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={6}>
                            <h6><strong>Nombre total d'employés : </strong>
                                {this.state.organization.employeesNumber}
                            </h6>
                        </Col>
                        <Col s={6}>
                            <h6><strong>Nombre d'équivalents plein temps : </strong>
                                {this.state.organization.fullTimeNumber}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                            <h6><strong>Description générale de l'organisation : </strong>
                                {this.state.organization.generalDescription}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                            <h6><strong>Brève description des processus et produits exclus et justification de
                                l'exclusion : </strong>
                                {this.state.organization.outsourcedProcessProductsDescription}
                            </h6>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default OrganizationForOrgUsersInfo;
