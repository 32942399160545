import React, { Component } from 'react'
import {
  Row,
  Col,
  Collection,
  CollectionItem,
  TextInput,
  Textarea,
  Select,
  Icon,
  Button,
  DatePicker,
  TimePicker,
  Table
} from 'react-materialize'
import { toast, ToastContainer } from 'react-toastify'

class AuditPlanEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      auditPlan: {},
      auditors: [],
      auditPlanAuditors: [],
      plans: [],
      dataChanged: false
    }
  }

  componentDidMount () {
    this.getAuditPlan()
    fetch('/server/auditors')
    .then(res => res.json())
    .then(auditors =>
      this.setState({auditors}))
  }

  getAuditPlan () {
    const {id} = this.props.id
    fetch(`/server/reports/${id}/auditPlan`)
    .then(res => res.json())
    .then(auditPlan => {
      this.setState({auditPlan})
      this.setState({plans: auditPlan.plans})
      this.setState({auditPlanAuditors: auditPlan.auditors})
    })
    .then(() => {
      this.setState(state => {
        const auditPlanAuditors = state.auditPlanAuditors.map((item, j) => {
          return {
            fk_auditor: item.auditor_auditPlan.fk_auditor,
            fk_auditPlan: item.auditor_auditPlan.fk_auditPlan,
            createdAt: item.auditor_auditPlan.createdAt,
            updatedAt: item.auditor_auditPlan.updatedAt
          }
        })

        return {
          auditPlanAuditors
        }
      })
    })
  }

  checkDataAlreadyChanged () {
    if (!this.state.dataChanged) {
      this.setState({
        dataChanged: true
      })
      this.props.disableTabs(1)
    }
  }

  changeAuditor (event, i) {
    this.checkDataAlreadyChanged()

    const newAuditor = event.target.value
    this.setState(state => {
      const auditPlanAuditors = state.auditPlanAuditors.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            fk_auditor: newAuditor
          }
        } else {
          return item
        }
      })

      return {
        auditPlanAuditors
      }
    })
    console.log(this.state.auditPlanAuditors)
  }

  changeServices (event, i) {
    this.checkDataAlreadyChanged()

    const newServices = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            services: newServices
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  };

  changePlace (event, i) {
    this.checkDataAlreadyChanged()

    const newPlace = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            place: newPlace
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  changeAuditedStaff (event, i) {
    this.checkDataAlreadyChanged()

    const newAuditedStaff = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            auditedStaff: newAuditedStaff
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  changeStandardChapter (event, i) {
    this.checkDataAlreadyChanged()

    const newStandardChapter = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            standardChapter: newStandardChapter
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  changePlanAuditors (event, i) {
    this.checkDataAlreadyChanged()

    const newPlanAuditor = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            auditors: newPlanAuditor
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  changeAuditManager (event) {
    this.checkDataAlreadyChanged()

    this.setState({
      auditPlan: {
        ...this.state.auditPlan,
        fk_auditor_auditManager: event.target.value
      }
    })
  }

  changeStartDate = (date, i) => {
    this.checkDataAlreadyChanged()

    const newDate = new Date(date)
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          const goodTime = new Date(item.startDate)
          return {
            ...item,
            startDate: new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(),
              goodTime.getHours(), goodTime.getMinutes())
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  changeStartTime (event, i) {
    this.checkDataAlreadyChanged()

    const newStartTime = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            startTime: newStartTime
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  changeEndTime (event, i) {
    this.checkDataAlreadyChanged()

    const newEndTime = event.target.value
    this.setState(state => {
      const plans = state.plans.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            endTime: newEndTime
          }
        } else {
          return item
        }
      })

      return {
        plans
      }
    })
  }

  addAuditor () {
    this.checkDataAlreadyChanged()

    this.setState(state => {
      const auditPlanAuditors = state.auditPlanAuditors.concat({
        fk_auditor: 1,
        fk_auditPlan: this.state.auditPlan.pk_auditPlan
      })

      return {
        auditPlanAuditors
      }
    })
  }

  delAuditor (i) {
    this.checkDataAlreadyChanged()

    this.setState(state => {
      const auditPlanAuditors = state.auditPlanAuditors.filter((item, j) => i !== j)
      return {
        auditPlanAuditors
      }
    })
  }

  addPlan () {
    this.checkDataAlreadyChanged()
    this.setState(state => {
      const plans = state.plans.concat({
        services: '',
        startDate: new Date(),
        startTime: '',
        endTime: '',
        place: '',
        auditedStaff: '',
        standardChapter: '',
        fk_auditor: 1,
        fk_auditPlan: this.state.auditPlan.pk_auditPlan,
        createdAt: new Date(),
        updatedAt: new Date()
      })

      return {
        plans
      }
    })
  }

  delPlan (i) {
    this.checkDataAlreadyChanged()
    this.setState(state => {
      const plans = state.plans.filter((item, j) => i !== j)
      return {
        plans
      }
    })
  }

  updateAuditPlan () {
    fetch(`/server/reports/update/auditPlan/${this.state.auditPlan.pk_auditPlan}`, {
      method: 'POST',
      body: JSON.stringify(this.state.auditPlan),
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
      return response.json()
    })
    .then((message) => {
      this.setState({message})
    })
    .then(() => {
      if (this.state.message.updated) {
        toast.success(this.state.message.msg)
      } else {
        toast.error(this.state.message.msg)
      }
    })
    const orderedPlans = this.state.plans.sort((a, b) => a.pk_plan > b.pk_plan ? 1 : -1);
    fetch(`/server/reports/update/auditPlan/${this.state.auditPlan.pk_auditPlan}/plans`, {
      method: 'POST',
      body: JSON.stringify(orderedPlans),
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
      return response.json()
    })
    .then((message) => {
      this.setState({message})
    })
    .then(() => {
      if (this.state.message.updated) {
        toast.success(this.state.message.msg)
        this.getAuditPlan()
      } else {
        toast.error(this.state.message.msg)
      }
    })
    fetch(`/server/reports/update/auditPlan/${this.state.auditPlan.pk_auditPlan}/auditors`, {
      method: 'POST',
      body: JSON.stringify(this.state.auditPlanAuditors),
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
      return response.json()
    })
    .then((message) => {
      this.setState({message})
    })
    .then(() => {
      if (this.state.message.updated) {
        toast.success(this.state.message.msg)
      } else {
        toast.error(this.state.message.msg)
      }
    })
    this.setState({
      dataChanged: false
    })
    this.props.enableTabs()
  }

  cancelUpdate () {
    this.setState({
      dataChanged: false
    })
    this.props.enableTabs()
    this.getAuditPlan()
  }

  render () {
    return (
      <div>
        <Row>
          <Col m={12}
               s={12}>
            <Collection className="z-depth-1">
              <CollectionItem>
                <Select className="select-collection"
                        label="Auditeur Lead"
                        value={this.state.auditPlan.fk_auditor_auditManager}
                        s={12}
                        m={12}
                        onChange={(event) => this.changeAuditManager(event)}>
                  {this.state.auditors.map((auditor, i) =>
                    <option value={auditor.pk_auditor}>
                      {auditor.firstName} {auditor.lastName}
                    </option>
                  )}
                </Select>
                <Col>
                  <Row>
                    <h5 className="left-align">Auditeurs</h5>
                  </Row>
                  <Row>
                    <Button waves="light"
                            className="blue left"
                            onClick={() => this.addAuditor()}><Icon>add</Icon>
                    </Button>
                  </Row>
                </Col>
                <Row>
                  <Col m={12}
                       s={12}>
                    {this.state.auditPlanAuditors.map((auditor, i) =>
                      <div>
                        <Button flat
                                waves="light"
                                className="red-text right"
                                onClick={() => this.delAuditor(i)}><Icon>clear</Icon></Button>
                        <Select className="select-collection"
                                label="Auditeur"
                                value={auditor.fk_auditor}
                                s={12}
                                m={12}
                                onChange={(event) => this.changeAuditor(event, i)}>
                          {this.state.auditors.map((auditor, i) =>
                            <option value={auditor.pk_auditor}>
                              {auditor.firstName} {auditor.lastName}
                            </option>
                          )}
                        </Select>
                      </div>
                    )}
                  </Col>
                </Row>
                <Col>
                  <Row>
                    <h5 className="left-align">Plans</h5>
                  </Row>
                </Col>
                <Table className="centered highlight hide-on-small-only">
                  <thead>
                  <tr>
                    <th data-field="date-start">
                      Date
                    </th>
                    <th data-field="time-start">
                      Heure début
                    </th>
                    <th data-field="time-end">
                      Heure fin
                    </th>
                    <th data-field="service">
                      Service, lieu et personnel audité
                    </th>
                    <th data-field="standard-chapter">
                      Thèmes et chapitres normes
                    </th>
                    <th data-field="auditor">
                      Auditeur(s)
                    </th>
                    <th data-field="remove"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.plans.map((plan, i) =>
                    <tr>
                      <td>
                        <DatePicker options={{
                          defaultDate: new Date(plan.startDate),
                          setDefaultDate: true,
                          format: 'dd/mm/yyyy'
                        }}
                                    label="Date"
                                    icon="date_range"
                                    onChange={(event) => this.changeStartDate(event, i)} />
                      </td>
                      <td>
                        <TextInput s={12}
                                   m={12}
                                   label="Heure début"
                                   placeholder="hh:mm"
                                   value={plan.startTime}
                                   validate
                                   onChange={(event) => this.changeStartTime(event, i)} />
                      </td>
                      <td>
                        <TextInput s={12}
                                   m={12}
                                   label="Heure fin"
                                   placeholder="hh:mm"
                                   value={plan.endTime}
                                   validate
                                   onChange={(event) => this.changeEndTime(event, i)} />
                      </td>
                      <td>
                        <Textarea s={12}
                                  m={12}
                                  value={plan.services}
                                  label="Service, lieu et personnel audité"
                                  validate
                                  onChange={(event) => this.changeServices(event, i)} />
                      </td>
                      <td>
                        <Textarea s={12}
                                  m={12}
                                  value={plan.standardChapter}
                                  label="Thèmes et chapitres normes"
                                  validate
                                  onChange={(event) => this.changeStandardChapter(event, i)} />
                      </td>
                      <td>
                        <Textarea s={12}
                                  m={12}
                                  value={plan.auditors}
                                  label="Auditeur(s)"
                                  validate
                                  onChange={(event) => this.changePlanAuditors(event, i)} />
                      </td>
                      <td>
                        <Button flat
                                waves="light"
                                className="red-text right"
                                onClick={() => this.delPlan(i)}><Icon>clear</Icon></Button>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
                <div className="hide-on-med-only hide-on-med-only hide-on-large-only hide-on-extra-large-only">
                  {this.state.plans.map((plan, i) =>
                    <Row>
                      <Col m={12}
                           s={12}>
                        <Collection className="z-depth-1">
                          <CollectionItem>
                            <Button flat
                                    waves="light"
                                    className="red-text right"
                                    onClick={() => this.delPlan(i)}><Icon>clear</Icon></Button>
                            <DatePicker options={{
                              defaultDate: new Date(plan.startDate),
                              setDefaultDate: true,
                              format: 'dd/mm/yyyy'
                            }}
                                        label="Date"
                                        icon="date_range"
                                        onChange={(event) => this.changeStartDate(event, i)} />
                            <TextInput s={12}
                                       m={12}
                                       label="Heure début"
                                       placeholder="hh:mm"
                                       value={plan.startTime}
                                       validate
                                       onChange={(event) => this.changeStartTime(event, i)} />
                            <TextInput s={12}
                                       m={12}
                                       label="Heure fin"
                                       placeholder="hh:mm"
                                       value={plan.endTime}
                                       validate
                                       onChange={(event) => this.changeEndTime(event, i)} />
                            <TextInput s={12}
                                       m={12}
                                       value={plan.services}
                                       label="Services"
                                       validate
                                       onChange={(event) => this.changeServices(event, i)} />
                            <TextInput s={12}
                                       m={12}
                                       value={plan.place}
                                       label="Place"
                                       validate
                                       onChange={(date) => this.changePlace(date, i)} />
                            <TextInput s={12}
                                       m={12}
                                       value={plan.auditedStaff}
                                       label="Personnel audité"
                                       validate
                                       onChange={(event) => this.changeAuditedStaff(event, i)} />
                            <TextInput s={12}
                                       m={12}
                                       value={plan.standardChapter}
                                       label="Chapitre standard"
                                       validate
                                       onChange={(event) => this.changeStandardChapter(event, i)} />
                            <Textarea s={12}
                                      m={12}
                                      value={plan.auditors}
                                      label="Auditeur(s)"
                                      validate
                                      onChange={(event) => this.changePlanAuditors(event, i)} />
                          </CollectionItem>
                        </Collection>
                      </Col>
                    </Row>
                  )}
                </div>
                <Col>
                  <Row>
                    <Button waves="light"
                            className="blue left"
                            onClick={() => this.addPlan()}><Icon>add</Icon>
                    </Button>
                  </Row>
                </Col>
              </CollectionItem>
            </Collection>
          </Col>
        </Row>
        <Button floating
                fab
                icon="edit"
                className="blue"
                large
                disabled={!this.state.dataChanged}>
          {this.state.dataChanged &&
          <Button waves="light"
                  floating
                  icon="cancel"
                  className="red"
                  tooltip="Annuler modifications"
                  tooltipOptions={{position: 'left'}}
                  onClick={() => this.cancelUpdate()} />
          }
          {this.state.dataChanged &&
          <Button waves="light"
                  floating
                  icon="save"
                  className="green"
                  tooltip="Sauvegarder"
                  tooltipOptions={{position: 'left'}}
                  onClick={() => this.updateAuditPlan()} />
          }

        </Button>
      </div>
    )
  }
}

export default AuditPlanEdit
