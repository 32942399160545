import React, {Component} from 'react';
import {Row, Col, Collection, CollectionItem, Card, Button, Icon, Modal} from 'react-materialize';
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();

class OrganizationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            cookie: jwt.decode(cookies.get('token'))
        };
    }

    componentDidMount() {
        this.getOrganizations();
    }

    getOrganizations() {
        if (this.state.cookie.isAdmin === 1)
            fetch('/server/organizations')
                .then(res => res.json())
                .then(organizations =>
                    this.setState({organizations}));
        else
            fetch(`/server/organizations/auditorNotAdmin/${this.state.cookie.pk_auditor}`)
                .then(res => res.json())
                .then(organizations =>
                    this.setState({organizations}));
    }

    deleteOrganization(pk_organization) {
        fetch(`/server/organizations/delete/${pk_organization}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getOrganizations();
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Card>
                            <Collection>
                                {this.state.organizations.length > 0 ?
                                    <div>
                                        {this.state.organizations.map(organization =>
                                            <CollectionItem className="avatar" key={organization.pk_organization}>
                                                <a href={`/Organization/${organization.pk_organization}`}><span
                                                    className="title">{organization.name}</span>
                                                    <p>
                                                        {organization.email}
                                                        <br/>
                                                        {organization.phone}
                                                        <br/>
                                                        {organization.website}
                                                    </p>
                                                </a>
                                                {this.state.cookie.isAdmin === 1 &&
                                                <Modal trigger={<Button className="secondary-content red">
                                                    <Icon className="white-text">delete</Icon>
                                                </Button>}>
                                                    <div className="center-align">
                                                        <h5>Voulez-vous vraiment supprimer cette organisation ?</h5>
                                                        <Button className="green center-align btn-modal"
                                                                onClick={() => this.deleteOrganization(organization.pk_organization)}>Confirmer</Button>
                                                        <Button className="red center-align btn-modal"
                                                                modal="close">Annuler</Button>
                                                    </div>
                                                </Modal>
                                                }
                                            </CollectionItem>
                                        )}
                                    </div>
                                    :
                                    <h6>Vous n'avez accès à aucune organisation</h6>
                                }
                            </Collection>
                        </Card>
                    </Col>

                </Row>

            </div>
        );
    }
}

export default OrganizationsList;
