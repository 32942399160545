import React, {Component} from 'react';
import './VariousDocumentEdit.css';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    Icon,
    Button,
    Textarea,
    Table
} from 'react-materialize';
import InputFiles from 'react-input-files';
import {toast, ToastContainer} from "react-toastify";
import axios from 'axios';

class VariousDocumentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variousDocuments: [],
            files: [],
            dataChanged: false,
            // docFilePath: 'http://localhost:5000', // dev
            docFilePath: 'https://datacert.ch' // prod
        };
    }

    componentDidMount() {
        this.getDocuments();
    }

    getDocuments() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}/variousDocuments`)
            .then(res => res.json())
            .then(variousDocuments =>
                this.setState({variousDocuments}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(8);
        }
    }

    changeFilename(event, i) {
        this.checkDataAlreadyChanged();

        const file = event[0];
        this.setState(state => {
            const variousDocuments = state.variousDocuments.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        name: file.name
                    };
                } else {
                    return item;
                }
            });

            return {
                variousDocuments
            };
        });
        this.setState(state => {
            const files = state.files.concat({
                file
            });

            return {
                files
            };
        });
    }

    changeDescription(event, i) {
        this.checkDataAlreadyChanged();

        const newDescription = event.target.value;
        this.setState(state => {
            const variousDocuments = state.variousDocuments.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        description: newDescription
                    };
                } else {
                    return item;
                }
            });

            return {
                variousDocuments
            };
        });
    }

    addDocument() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const variousDocuments = state.variousDocuments.concat({
                "name": "",
                "description": "",
                "fk_report": id,
            });

            return {
                variousDocuments
            };
        });
    }

    delDocument(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const variousDocuments = state.variousDocuments.filter((item, j) => i !== j);
            return {
                variousDocuments
            };
        });
    }

    updateDocuments() {
        const {id} = this.props.id;
        fetch(`/server/reports/update/${id}/variousDocuments/`, {
            method: 'POST',
            body: JSON.stringify(this.state.variousDocuments),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        this.state.files.map((file) => {
            const data = new FormData();
            data.append('file', file.file);
            axios.post('/server/files/upload', data)
                .then(res => {
                    console.log(res.statusText)
                })
        });

        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    }

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getDocuments();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Collection className="z-depth-1">
                            <CollectionItem>
                                <Table className="centered highlight">
                                    <thead>
                                    <tr className="table-document">
                                        <th>
                                            Document
                                        </th>
                                        <th className="table-description">
                                            Description
                                        </th>
                                        <th className="table-file"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.variousDocuments.map((doc, i) =>
                                        <tr>
                                            {(doc.name === null || doc.name === '') ? (
                                                <td className="table-document">
                                                    <InputFiles
                                                        onChange={(event) => this.changeFilename(event, i)} accept="*">
                                                        <Button
                                                            className="blue upload-button"><Icon>file_upload</Icon> Télécharger</Button>
                                                    </InputFiles>

                                                </td>
                                            ) : (
                                                <td className="table-document">
                                                    <a href={`${this.state.docFilePath}/${doc.name}`}
                                                       target="_blank" rel="noopener noreferrer"><Icon>attach_file</Icon> {doc.name}</a>
                                                </td>
                                            )}
                                            <td className="table-description">
                                                <Textarea value={doc.description} s={12} m={12}
                                                          onChange={(event) => this.changeDescription(event, i)}/>
                                            </td>
                                            <td><Button flat waves="light" className="red-text right"
                                                        onClick={() => this.delDocument(i)}><Icon>clear</Icon></Button>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Button waves="light" className="blue left"
                                        onClick={() => this.addDocument()}><Icon>add</Icon>
                                </Button>
                            </CollectionItem>
                        </Collection>
                    </Col>
                </Row>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateDocuments()}/>
                    }

                </Button>
            </div>
        );
    }
}

export default VariousDocumentEdit;
