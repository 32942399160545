import React, {Component} from 'react';
import {Button} from 'react-materialize';
import {NavLink} from "react-router-dom";

class FabAddPage extends Component {
    render() {
        return (
            <div>
                <NavLink to={{
                    pathname: this.props.link,
                    state: {
                        id: this.props.id
                    }
                }}>
                    <Button
                        floating
                        fab
                        icon="add"
                        className="green"
                        large
                        wave="light"
                    >
                    </Button>
                </NavLink>
            </div>
        );
    }
}

export default FabAddPage;
