import React, {Component} from 'react';
import {Tabs, Tab} from 'react-materialize';
import './ReportEdit.css';
import ReportInfoEdit from "../ReportInfoEdit/ReportInfoEdit";
import AuditPlanEdit from "../AuditPlanEdit/AuditPlanEdit";
import ChecklistReportEdit from "../ChecklistReportEdit/ChecklistReportEdit";
import GeneralFeelingEdit from "../GeneralFeelingEdit/GeneralFeelingEdit";
import AuditReportEdit from "../AuditReportEdit/AuditReportEdit";
import ConclusionEdit from "../ConclusionEdit/ConclusionEdit";
import CertificationDecision from "../CertificationDecisionEdit/CertificationDecision";
import VariousDocumentEdit from "../VariousDocumentEdit/VariousDocumentEdit";
import AuditedPeopleEdit from "../AuditedPeopleEdit/AuditedPeopleEdit";
import {ToastContainer} from "react-toastify";

class ReportEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 0,
                    name: "Informations",
                    disabled: false
                },
                {
                    id: 1,
                    name: "Plan d'audit",
                    disabled: false
                },
                {
                    id: 2,
                    name: "Checklists",
                    disabled: false
                },
                {
                    id: 3,
                    name: "Equipe audités",
                    disabled: false
                },
                {
                    id: 4,
                    name: "Impression générale",
                    disabled: false
                },
                {
                    id: 5,
                    name: "Constats d'audit",
                    disabled: false
                },
                {
                    id: 6,
                    name: "Conclusion",
                    disabled: false
                },
                {
                    id: 7,
                    name: "Décision de certification",
                    disabled: false
                },
                {
                    id: 8,
                    name: "Documentations liées à l’audit",
                    disabled: false
                }
            ]
        }
    }

    disableTabs = (activeTabId) => {
        this.setState(state => {
            const tabs = state.tabs.map((tab, i) => {
                if (i !== activeTabId) {
                    return {
                        ...tab,
                        disabled: true
                    };
                } else {
                    return tab;
                }
            });

            return {
                tabs
            };
        });
    };

    enableTabs = () => {
        this.setState(state => {
            const tabs = state.tabs.map((tab, i) => {
                return {
                    ...tab,
                    disabled: false
                };
            });
            return {
                tabs
            };
        });
    };

    render() {
        return (
            <div>
                <Tabs className="tab-demo tabs-fixed-width z-depth-1">
                    <Tab className="" title={this.state.tabs[0].name} disabled={this.state.tabs[0].disabled} active>
                        <ReportInfoEdit id={this.props.id} disableTabs={this.disableTabs} enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[1].name} disabled={this.state.tabs[1].disabled}>
                        <AuditPlanEdit id={this.props.id} disableTabs={this.disableTabs} enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[2].name} disabled={this.state.tabs[2].disabled}>
                        <ChecklistReportEdit id={this.props.id} disableTabs={this.disableTabs}
                                             enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[3].name} disabled={this.state.tabs[3].disabled}>
                        <AuditedPeopleEdit id={this.props.id} disableTabs={this.disableTabs}
                                           enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[4].name} disabled={this.state.tabs[4].disabled}>
                        <GeneralFeelingEdit id={this.props.id} disableTabs={this.disableTabs}
                                            enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[5].name} disabled={this.state.tabs[5].disabled}>
                        <AuditReportEdit id={this.props.id} disableTabs={this.disableTabs}
                                         enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[6].name} disabled={this.state.tabs[6].disabled}>
                        <ConclusionEdit id={this.props.id} disableTabs={this.disableTabs} enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[7].name} disabled={this.state.tabs[7].disabled}>
                        <CertificationDecision id={this.props.id} disableTabs={this.disableTabs}
                                               enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[8].name} disabled={this.state.tabs[8].disabled}>
                        <VariousDocumentEdit id={this.props.id} disableTabs={this.disableTabs}
                                             enableTabs={this.enableTabs}/>
                    </Tab>
                </Tabs>
                <ToastContainer autoClose={2000} position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default ReportEdit;
