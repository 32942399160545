import React, {Component} from 'react';
import {TextInput, Textarea, Button, Card, Row, Icon} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class ChecklistEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checklist: {},
            questions: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getChecklist();
    }

    getChecklist() {
        const {id} = this.props.id;
        fetch(`/server/checklists/${id}`)
            .then(res => res.json())
            .then(checklist => {
                this.setState({checklist: checklist});
                this.setState({questions: checklist.questions});
            });
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
        }
    }

    changeName(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            checklist: {
                ...this.state.checklist,
                name: event.target.value
            }
        });
    };

    changeQuestion(event, i) {
        this.checkDataAlreadyChanged();

        const newQuestion = event.target.value;
        this.setState(state => {
            const questions = state.questions.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        question: newQuestion
                    };
                } else {
                    return item;
                }
            });

            return {
                questions
            };
        });
    };

    changePoint(event, i) {
        this.checkDataAlreadyChanged();

        const newPoint = event.target.value;
        this.setState(state => {
            const questions = state.questions.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        point: newPoint
                    };
                } else {
                    return item;
                }
            });

            return {
                questions
            };
        });
    };

    addQuestion() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const questions = state.questions.concat({
                question: "",
                position: this.state.questions.length + 1,
                point: 1,
                fk_checklist: id,
                createdAt: new Date(),
                updatedAt: new Date()
            });

            return {
                questions
            };
        });
    }

    delQuestion(position) {
        this.checkDataAlreadyChanged();

        this.setState(state => {
            const questions = state.questions.filter((item, j) => item.position !== position);
            console.log(JSON.stringify(questions));

            return {
                questions
            };
        });
        this.setState(state => {
            const questions = state.questions.map((item, j) => {
                if (item.position >= position) {
                    return {
                        ...item,
                        position: item.position - 1
                    };
                } else {
                    return item;
                }
            });

            return {
                questions
            };
        });
    }

    upQuestion(position) {
        this.checkDataAlreadyChanged();

        this.setState(state => {
            const questions = state.questions.map((item, j) => {
                if (item.position + 1 === position) {
                    return {
                        ...item,
                        position: item.position + 1
                    };
                } else if (item.position === position) {
                    return {
                        ...item,
                        position: item.position - 1
                    };
                } else {
                    return item;
                }
            });

            return {
                questions
            };
        }, () => this.sortQuestions());
    }

    downQuestion(position) {
        this.checkDataAlreadyChanged();

        this.setState(state => {
            const questions = state.questions.map((item, j) => {
                if (item.position === position) {
                    return {
                        ...item,
                        position: item.position + 1
                    };
                } else if (item.position - 1 === position) {
                    return {
                        ...item,
                        position: item.position - 1
                    };
                } else {
                    return item;
                }
            });

            return {
                questions
            };
        }, () => this.sortQuestions());
    }

    sortQuestions() {
        let sortedQuestions = this.state.questions;
        this.setState({
            questions: sortedQuestions.sort((a, b) => a.position - b.position)
        }, () =>
            console.log(this.state.questions));
    };

    updateChecklist() {
        const {id} = this.props.id;
        fetch(`/server/checklists/update/${id}`, {
            method: 'POST',
            body: JSON.stringify(this.state.checklist),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/checklists/update/${id}/questions`, {
            method: 'POST',
            body: JSON.stringify(this.state.questions),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        this.setState({
            dataChanged: false
        });
    };

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.getChecklist();
    }

    render() {
        return (
            <div>
                <Card>
                    <TextInput value={this.state.checklist.name} label="Checklist Name"
                               onChange={(event) => this.changeName(event)}/>
                    <h5 className="left-align">Questions</h5>
                    <Row>
                        <Button waves="light" className="blue left"
                                onClick={() => this.addQuestion()}><Icon>add</Icon>
                        </Button>
                    </Row>
                    {this.state.questions.map((question, i) =>
                        <Card>
                            <Button flat waves="light" className="red-text right"
                                    onClick={() => this.delQuestion(question.position)}><Icon>clear</Icon></Button>
                            <Textarea value={question.question} label="Question" rows={10}
                                       onChange={(event) => this.changeQuestion(event, i)}/>
                            <TextInput value={question.point} label="Point" type="number"
                                       onChange={(event) => this.changePoint(event, i)}/>
                            {question.position > 1 &&
                            <Button waves="light" className="blue left"
                                    onClick={() => this.upQuestion(question.position)}><Icon>arrow_upward</Icon></Button>}
                            {question.position < (this.state.questions.length) &&
                            <Button waves="light" className="blue left"
                                    onClick={() => this.downQuestion(question.position)}><Icon>arrow_downward</Icon></Button>}
                            <br/>
                        </Card>
                    )}
                    <Button
                        floating
                        fab
                        icon="edit"
                        className="blue"
                        large
                        disabled={!this.state.dataChanged}
                    >
                        {this.state.dataChanged &&
                        <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                                tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                        }
                        {this.state.dataChanged &&
                        <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                                tooltipOptions={{position: 'left'}} onClick={() => this.updateChecklist()}/>
                        }

                    </Button>
                    <ToastContainer position="bottom-center" hideProgressBar="true"/>
                </Card>
            </div>
        );
    }
}

export default ChecklistEdit;
