import React, {Component} from 'react';
import {Tabs, Tab} from 'react-materialize';
import OrganizationInfo from "../OrganizationInfo/OrganizationInfo";
import OrganizationReportsList from "../OrganizationReportsList/OrganizationReportsList";


class OrganizationR extends Component {
    render() {

        return (
            <div>
                <Tabs className="tab-demo tabs-fixed-width z-depth-1"
                      options={{swipeable: false, responsiveThreshold: 1000}}>
                    <Tab className="" title="Informations" active>
                        <OrganizationInfo id={this.props.id}/>
                    </Tab>
                    <Tab className="" title="Rapports">
                        <OrganizationReportsList id={this.props.id}/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default OrganizationR;
