import React, {Component} from 'react';
import './AddOrganization.css';
import NewOrganizationForm from "../../components/NewOrganizationForm/NewOrganizationForm";
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const history = createBrowserHistory({
    forceRefresh: true
});
const cookies = new Cookies();

class AddOrganization extends Component {
    render() {
        if (jwt.decode(cookies.get('token')).isAdmin !== 1)
            history.push('/');
        return (
            <div>
                <NewOrganizationForm/>
            </div>
        );
    }
}

export default AddOrganization;
