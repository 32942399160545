import React, {Component} from 'react';
import './Header.css';
import {Navbar, NavItem, Dropdown, Icon, Divider} from 'react-materialize';
import {createBrowserHistory} from "history";
import Cookies from 'universal-cookie';

const jwt = require('jsonwebtoken');
const history = createBrowserHistory({
    forceRefresh: true
});
const cookies = new Cookies();

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token'))
        };
    }

    componentDidMount() {
    }

    logout() {
        fetch('/server/login/logout')
            .then(res => {
                if (res.status === 200) {
                    history.push('/');
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <div className="navbar-fixed no-print" id="menu-header">
                {this.state.cookie && this.state.cookie.isAdmin === 1 && !this.state.cookie.fk_organization &&
                <Navbar className="blue darken-3" brand={<a id="header-logo" href="/">DataCert</a>} alignLinks="right"
                        options={{closeOnClick: true}} fixed>
                    <Dropdown options={{alignment: 'left', closeOnClick: true, coverTrigger: true}} trigger={
                        <a>Auditeurs<Icon className="right">arrow_drop_down</Icon></a>
                    }>
                        <a href="/Auditors">
                            <Icon>list</Icon>Liste
                        </a>
                        <a href="/AddAuditor">
                            <Icon>add</Icon>Ajouter
                        </a>
                    </Dropdown>
                    <Dropdown options={{alignment: 'left', closeOnClick: true, coverTrigger: true}} trigger={
                        <a>Organisations<Icon className="right">arrow_drop_down</Icon></a>
                    }>
                        <a href="/Organizations">
                            <Icon>list</Icon>Liste
                        </a>
                        <a href="/AddOrganization">
                            <Icon>add</Icon>Ajouter
                        </a>
                    </Dropdown>
                    <Dropdown options={{alignment: 'left', closeOnClick: true, coverTrigger: true}} trigger={
                        <a>Checklists<Icon className="right">arrow_drop_down</Icon></a>
                    }>
                        <a href="/Checklists">
                            <Icon>list</Icon>Liste
                        </a>
                        <a href="/AddChecklist">
                            <Icon>add</Icon>Ajouter
                        </a>
                    </Dropdown>
                    <Dropdown options={{alignment: 'left', closeOnClick: true, coverTrigger: true}} trigger={
                        <a className="light-blue-text"><Icon
                            className="left">person</Icon>{this.state.cookie.firstName} {this.state.cookie.lastName}
                            <Icon className="right">more_vert</Icon></a>
                    }>
                        <a href="/Settings">
                            <Icon>settings</Icon> Réglages
                        </a>
                        <Divider/>
                        <a onClick={() => this.logout()}>
                            <Icon>logout</Icon> Déconnexion
                        </a>
                    </Dropdown>
                </Navbar>
                }
                {this.state.cookie && this.state.cookie.isAdmin === 0 && !this.state.cookie.fk_organization &&
                <Navbar className="blue darken-3" brand={<a id="header-logo" href="/">DataCert</a>} alignLinks="right"
                        options={{closeOnClick: true}} fixed>
                    <NavItem href="/Organizations">
                        Organisations
                    </NavItem>
                    <Dropdown options={{alignment: 'left', closeOnClick: true, coverTrigger: true}} trigger={
                        <a className="light-blue-text"><Icon
                            className="left">person</Icon>{this.state.cookie.firstName} {this.state.cookie.lastName}
                            <Icon className="right">more_vert</Icon></a>
                    }>
                        <a href="/Settings">
                            <Icon>settings</Icon> Réglages
                        </a>
                        <Divider/>
                        <a onClick={() => this.logout()}>
                            <Icon>logout</Icon> Déconnexion
                        </a>
                    </Dropdown>
                </Navbar>
                }
                {this.state.cookie && this.state.cookie.fk_organization &&
                <Navbar className="blue darken-3" brand={<a id="header-logo" href="/">DataCert</a>} alignLinks="right"
                        options={{closeOnClick: true}} fixed>
                    <Dropdown options={{alignment: 'left', closeOnClick: true, coverTrigger: true}} trigger={
                        <a className="light-blue-text"><Icon
                            className="left">person</Icon>{this.state.cookie.firstName} {this.state.cookie.lastName}
                            <Icon className="right">more_vert</Icon></a>
                    }>
                        <a href="/Settings">
                            <Icon>settings</Icon> Réglages
                        </a>
                        <Divider/>
                        <a onClick={() => this.logout()}>
                            <Icon>logout</Icon> Déconnexion
                        </a>
                    </Dropdown>
                </Navbar>
                }
            </div>
        );
    }
}

export default Header;
