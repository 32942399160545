import React, {Component} from 'react';
import './ReportPdfViewer.css';
import {
    Icon,
    Table,
    Checkbox,
    Button,
    Row,
    Col,
    Collection,
    CollectionItem,
    Select,
    TextInput, Textarea, Card
} from 'react-materialize';
import Moment from 'react-moment';
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

class ReportPdfViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: {},
            organization: {},
            auditorList: [],
            statusList: [],
            reportData: {},
            auditPlan: {},
            auditPlanAuditors: [],
            plans: [],
            auditedPeople: [],
            gfms: [],
            gfm: [],
            gfperf: [],
            gfprod: [],
            gfr: [],
            auditReports: [],
            conclusion: {},
            conclusionComments: [],
            conclusionCompliances: [],
            conclusionNoncompliances: [],
            conclusionReviews: [],
            standards: [],
            interpretations: [],
        }
    }

    componentDidMount() {
        history.listen((location) => alert(location));
        const {id} = this.props.match.params;
        fetch(`/server/reports/${id}`)
            .then(res => res.json())
            .then(report => {
                this.setState({report});
            })
            .then(_ => {
                fetch(`/server/organizations/${this.state.report.fk_organization}`)
                    .then(res => res.json())
                    .then(organization =>
                        this.setState({organization}));
            })
        fetch(`/server/reports/${id}/pdf`)
            .then(res => res.json())
            .then(report => {
                this.setState({reportData: report});
                this.setState({auditPlan: report.auditPlan});
                this.setState({plans: report.auditPlan.plans});
                this.setState({auditPlanAuditors: report.auditPlan.auditors});
                this.setState({auditedPeople: report.auditedPeople});
                this.setState({gfms: report.generalFeelingManagementSystems});
                this.setState({gfm: report.generalFeelingManagements});
                this.setState({gfperf: report.generalFeelingPerformances});
                this.setState({gfprod: report.generalFeelingProductions});
                this.setState({gfr: report.generalFeelingRessources});
                this.setState({auditReports: report.auditReports});
            });
        fetch(`/server/reports/${id}/conclusion`)
            .then(res => res.json())
            .then(conclusion => {
                this.setState({conclusion});
                this.setState({conclusionComments: conclusion.conclusionComments});
                this.setState({conclusionCompliances: conclusion.conclusionCompliances});
                this.setState({conclusionNoncompliances: conclusion.conclusionNoncompliances});
                this.setState({conclusionReviews: conclusion.conclusionReviews});
            });
        fetch('/server/auditors')
            .then(res => res.json())
            .then(auditorList => this.setState({auditorList}));
        fetch('/server/status')
            .then(res => res.json())
            .then(statusList => this.setState({statusList}));
        fetch('/server/standards')
            .then(res => res.json())
            .then(standards =>
                this.setState({standards}));
        fetch('/server/interpretations')
            .then(res => res.json())
            .then(interpretations =>
                this.setState({interpretations}));
    };

    render() {
        return (
            <div>
                <p className="blue-text no-print">Cette page est un aperçu du contenu du fichier. Pour l'enregistrer,
                    veuillez imprimer cette page au format PDF.</p>
                <Button className="blue no-print" onClick={() => window.print()}>Imprimer</Button>
                <Col>
                    <Row>
                        <img className="left logo-pdf" src="/edelcert.png" width="200"/>
                    </Row>
                    <Row>
                        <h4 className="title-pdf">{this.state.reportData.name}</h4>
                    </Row>
                </Col>
                <h5>Informations sur l'organisation</h5>
                <Card>
                    <Table>
                        <tbody>
                        <tr>
                            <td><strong>Nom de l'organisation</strong></td>
                            <td>{this.state.organization.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Nom de la personne de contact</strong></td>
                            <td>{this.state.organization.contactFirstName} {this.state.organization.contactLastName}</td>
                        </tr>
                        <tr>
                            <td><strong>Email</strong></td>
                            <td>{this.state.organization.email}</td>
                        </tr>
                        <tr>
                            <td><strong>Téléphone</strong></td>
                            <td>{this.state.organization.phone}</td>
                        </tr>
                        <tr>
                            <td><strong>Site Web</strong></td>
                            <td>{this.state.organization.website}</td>
                        </tr>
                        <tr>
                            <td><strong>Nom du siège de l'organisation</strong></td>
                            <td>{this.state.organization.headquartersName}</td>
                        </tr>
                        <tr>
                            <td><strong>NP du siège</strong></td>
                            <td>{this.state.organization.headquartersNP}</td>
                        </tr>
                        <tr>
                            <td><strong>Adresse du Siège</strong></td>
                            <td>{this.state.organization.headquartersAddress}</td>
                        </tr>
                        <tr>
                            <td><strong>Ville du Siège</strong></td>
                            <td>{this.state.organization.headquartersLocation}</td>
                        </tr>
                        <tr>
                            <td><strong>Branches / Etablissements secondaires / Processus externalisés</strong></td>
                            <td>{this.state.organization.branches}</td>
                        </tr>
                        <tr>
                            <td><strong>Nombre total d'employés</strong></td>
                            <td>{this.state.organization.employeesNumber}</td>
                        </tr>
                        <tr>
                            <td><strong>Nombre d'équivalents plein temps</strong></td>
                            <td>{this.state.organization.fullTimeNumber}</td>
                        </tr>
                        <tr>
                            <td><strong>Description générale de l'organisation</strong></td>
                            <td>{this.state.organization.generalDescription}</td>
                        </tr>
                        <tr>
                            <td><strong>Brève description des processus et produits exclus et justification de
                                l'exclusion</strong></td>
                            <td>{this.state.organization.outsourcedProcessProductsDescription}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Card>
                <h5>Informations générales</h5>
                <Card>
                    <Table className="highlight">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Principaux changements au sein de l'organisation depuis le dernier audit</strong>
                            </td>
                            <td>{this.state.reportData.organizationChangesSinceLastAudit}</td>
                        </tr>
                        <tr>
                            <td><strong>Préparation de l’audit</strong></td>
                            <td>{this.state.reportData.auditPreparation}</td>
                        </tr>
                        <tr>
                            <td><strong>Méthode d'audit</strong></td>
                            <td>{this.state.reportData.auditMethod}</td>
                        </tr>
                        <tr>
                            <td><strong>Norme(s) et versions</strong></td>
                            <td>{this.state.reportData.standards}</td>
                        </tr>
                        <tr>
                            <td><strong>Autres normes certifiées</strong></td>
                            <td>{this.state.reportData.otherStandards}</td>
                        </tr>
                        <tr>
                            <td><strong>Organisme certificateur</strong></td>
                            <td>{this.state.reportData.certificationBody}</td>
                        </tr>
                        <tr>
                            <td><strong>Principaux changements au sein du système de management depuis le dernier audit</strong></td>
                            <td>{this.state.reportData.managementSystemChangesSinceLastAudit}</td>
                        </tr>
                        <tr>
                            <td><strong>Fréquence d'audit</strong></td>
                            <td>{this.state.reportData.auditFrequency}</td>
                        </tr>
                        <tr>
                            <td><strong>Nombre de jours d'audit</strong></td>
                            <td>{this.state.reportData.numberAuditDays}</td>
                        </tr>
                        <tr>
                            <td><strong>Durée de l'audit sur site (h)</strong></td>
                            <td>{this.state.reportData.onSiteAudit}</td>
                        </tr>
                        <tr>
                            <td><strong>Produits et activités exclus du périmètre de certification</strong></td>
                            <td>{this.state.reportData.excludedProductsAndActivities}</td>
                        </tr>
                        <tr>
                            <td><strong>Domaine d’application</strong></td>
                            <td>{this.state.reportData.applicationDomain}</td>
                        </tr>
                        <tr>
                            <td><strong>Processus ou produits externalisés</strong></td>
                            <td>{this.state.reportData.excludedProductsAndActivities}</td>
                        </tr>
                        <tr>
                            <td><strong>Exclusions de la norme</strong></td>
                            <td>{this.state.reportData.standardExclusion}</td>
                        </tr>
                        <tr>
                            <td><strong>Documents de référence</strong></td>
                            <td>{this.state.reportData.referenceDocuments}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Card>
                <h5>Plan d'audit</h5>
                <Card>
                    <Table className="highlight">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Auditeur lead</strong>
                            </td>
                            {this.state.auditorList.map((auditor, i) => {
                                    if (auditor.pk_auditor === this.state.auditPlan.fk_auditor_auditManager) {
                                        return <td>{auditor.firstName} {auditor.lastName}</td>
                                    }
                                }
                            )}
                        </tr>
                        </tbody>
                    </Table>
                  {this.state.auditPlanAuditors && this.state.auditPlanAuditors.length > 0 &&
                  <>
                      <h5 style={{textAlign: 'left'}}>Auditeurs</h5>
                        <p style={{textAlign: 'left'}}>{this.state.auditPlanAuditors.map((auditor, i) =>
                        <div>{auditor.firstName} {auditor.lastName}</div>
                      )}</p>
                  </>
                  }
                    <Table className="highlight">
                        <thead>
                        <tr>
                            <th>Date de début</th>
                            <th>Heure de début</th>
                            <th>Heure de fin</th>
                            <th>Service, lieu et personnel audité</th>
                            <th>Thèmes et chapitres normes</th>
                            <th>Auditeur(s)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.plans.map((plan, i) =>
                            <tr>
                                <td><Moment format="DD/MM/YYYY">{new Date(plan.startDate)}</Moment></td>
                                <td>{plan.startTime}</td>
                                <td>{plan.endTime}</td>
                                <td>{plan.services}</td>
                                <td>{plan.standardChapter}</td>
                                <td>{plan.auditors}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <h5>Equipe d'audités</h5>
                    <Table className="highlight">
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Fonction</th>
                            <th>Réunion d'ouverture</th>
                            <th>Audit sur site</th>
                            <th>Réunion de clôture</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.auditedPeople.map((auditedPerson, i) =>
                            <tr>
                                <td>{auditedPerson.firstName} {auditedPerson.lastName}</td>
                                <td>{auditedPerson.function}</td>
                                <td><Checkbox checked={auditedPerson.openingMeeting} disabled/></td>
                                <td><Checkbox checked={auditedPerson.onSiteAudit} disabled/></td>
                                <td><Checkbox checked={auditedPerson.closingMeeting} disabled/></td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Card>
                <h5>Impression générale</h5>
                <Card>
                    <div className="">
                        <h6>Impression générale – Système de management, contexte de l’organisation</h6>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>thumb_up</Icon> Points positifs</h6>}>
                            {this.state.gfms.map((g, i) =>
                                <CollectionItem className="general-feeling-text" centered>{g.content}</CollectionItem>
                            )}
                        </Collection>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>warning</Icon> Points à améliorer</h6>}>
                            <CollectionItem className="general-feeling-text" centered>Merci de vous référer aux
                                constats
                                d'audit</CollectionItem>
                        </Collection>

                        <h6>Impression générale - Management, Leadership et planification</h6>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>thumb_up</Icon> Points positifs</h6>}>
                            {this.state.gfm.map((g, i) =>
                                <CollectionItem className="general-feeling-text" centered>{g.content}</CollectionItem>
                            )}
                        </Collection>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>warning</Icon> Points à améliorer</h6>}>
                            <CollectionItem className="general-feeling-text" centered>Merci de vous référer aux
                                constats
                                d'audit</CollectionItem>
                        </Collection>

                        <h6>Impression générale – Ressources / support</h6>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>thumb_up</Icon> Points positifs</h6>}>
                            {this.state.gfprod.map((g, i) =>
                                <CollectionItem className="general-feeling-text" centered>{g.content}</CollectionItem>
                            )}
                        </Collection>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>warning</Icon> Points à améliorer</h6>}>
                            <CollectionItem className="general-feeling-text" centered>Merci de vous référer aux
                                constats
                                d'audit</CollectionItem>
                        </Collection>

                        <h6>Impression générale – Réalisation / opérations</h6>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>thumb_up</Icon> Points positifs</h6>}>
                            {this.state.gfr.map((g, i) =>
                                <CollectionItem className="general-feeling-text" centered>{g.content}</CollectionItem>
                            )}
                        </Collection>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>warning</Icon> Points à améliorer</h6>}>
                            <CollectionItem className="general-feeling-text" centered>Merci de vous référer aux
                                constats
                                d'audit</CollectionItem>
                        </Collection>

                        <h6>Impression générale – Evaluations de la performance / améliorations</h6>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>thumb_up</Icon> Points positifs</h6>}>
                            {this.state.gfperf.map((g, i) =>
                                <CollectionItem className="general-feeling-text" centered>{g.content}</CollectionItem>
                            )}
                        </Collection>
                        <Collection className="general-feeling-text"
                                    header={<h6><Icon>warning</Icon> Points à améliorer</h6>}>
                            <CollectionItem className="general-feeling-text" centered>Merci de vous référer aux
                                constats
                                d'audit</CollectionItem>
                        </Collection>
                    </div>
                </Card>
                <h5>Constats d'audit</h5>
                <Card>
                    <div className="">
                        <Row>
                            <Col m={12} s={12}>
                                {this.state.auditReports.map((auditReport, i) =>
                                    <Collection className="z-depth-1">
                                        <CollectionItem>
                                            <Table className="highlight">
                                                <tbody>
                                                <tr>
                                                  <td className="table-auditReport-td1"><strong>Interprétation</strong></td>
                                                  {this.state.interpretations.map((interpretation, i) => {
                                                      return interpretation.pk_interpretation === auditReport.fk_interpretation &&
                                                        (
                                                          <td className="table-auditReport-td2">{interpretation.name}</td>)
                                                    }
                                                  )}
                                                </tr>
                                                <tr>
                                                  <td className="table-auditReport-td1"><strong>Norme</strong></td>
                                                  {this.state.standards.map((standard, i) => {
                                                            return standard.pk_standard === auditReport.fk_standard &&
                                                                (<td className="table-auditReport-td2">{standard.name}</td>)
                                                        }
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="table-auditReport-td1"><strong>Chapitre</strong></td>
                                                    <td className="table-auditReport-td2">{auditReport.chapter}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-auditReport-td1"><strong>Description</strong></td>
                                                    <td className="table-auditReport-td2">{auditReport.description}</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                            {(auditReport.fk_interpretation === 3 || auditReport.fk_interpretation === 4
                                                || auditReport.fk_interpretation === '3' || auditReport.fk_interpretation === '4') &&
                                            <div>
                                                <Table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="table-auditReport-td1"><strong>Délai pour le traitement
                                                            de la
                                                            Non-conformité</strong>
                                                        </td>
                                                        <td className="table-auditReport-td2">{auditReport.noncomplianceDelayTreatment}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-auditReport-td1"><strong>Plan d’action (analyse de
                                                            la
                                                            cause
                                                            et action corrective planifiée)</strong>
                                                        </td>
                                                        <td className="table-auditReport-td2">{auditReport.actionPlan}</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                                <Table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="table-auditReport-td1-bis"><strong>Validation du plan
                                                            d’action
                                                            par l’auditeur</strong>
                                                        </td>
                                                        <td className="table-auditReport-td2-bis">{auditReport.actionPlanValidation}</td>
                                                        <td className="table-auditReport-td3"><strong>Validé</strong></td>
                                                        <td className="table-auditReport-td4"><Checkbox
                                                            checked={auditReport.APVVvalidated} disabled/></td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                                <Table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="table-auditReport-td1"><strong>Action corrective
                                                            réalisée</strong>
                                                        </td>
                                                        <td className="table-auditReport-td2">{auditReport.correctiveActionMade}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-auditReport-td1"><strong>Pièce jointe</strong></td>
                                                        <td className="table-auditReport-td2">{auditReport.attachment}</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                                <Table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="table-auditReport-td1-bis"><strong>Validation de l'action
                                                            corrective par l'auditeur</strong>
                                                        </td>
                                                        <td className="table-auditReport-td2-bis">{auditReport.correctiveActionValidation}</td>
                                                        <td className="table-auditReport-td3"><strong>Validé</strong></td>
                                                        <td className="table-auditReport-td4"><Checkbox
                                                            checked={auditReport.CAVValidated} disabled/></td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            }

                                        </CollectionItem>
                                    </Collection>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card>
                <h5>Conclusion</h5>
                <Card>
                    <h5>Revue du système de management</h5>
                    <Table className="highlight">
                        <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Oui</th>
                            <th>Commentaire</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.conclusionReviews.map((conclusion, i) =>
                            <tr>
                                <td>{conclusion.title}</td>
                                <td><Checkbox checked={conclusion.conclusionReview_Conclusion.yes} disabled/></td>
                                <td>{conclusion.conclusionReview_Conclusion.comment}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <h5>Commentaire sur l’utilisation de la marque</h5>
                    <Table className="highlight">
                        <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Oui</th>
                            <th>Commentaire</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.conclusionComments.map((conclusion, i) =>
                            <tr>
                                <td>{conclusion.title}</td>
                                <td><Checkbox checked={conclusion.conclusionComment_Conclusion.yes} disabled/></td>
                                <td>{conclusion.conclusionComment_Conclusion.comment}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <h5>Conformité du Système</h5>
                    <Table className="highlight">
                        <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Oui</th>
                            <th>Commentaire</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.conclusionCompliances.map((conclusion, i) =>
                            <tr>
                                <td>{conclusion.title}</td>
                                <td><Checkbox checked={conclusion.conclusionCompliance_Conclusion.yes} disabled/></td>
                                <td>{conclusion.conclusionCompliance_Conclusion.comment}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <h5>Non-conformité du Système</h5>
                    <Table className="highlight">
                        <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Oui</th>
                            <th>Commentaire</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.conclusionNoncompliances.map((conclusion, i) =>
                            <tr>
                                <td>{conclusion.title}</td>
                                <td><Checkbox checked={conclusion.conclusionNoncompliance_Conclusion.yes} disabled/>
                                </td>
                                <td>{conclusion.conclusionNoncompliance_Conclusion.comment}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <Table className="highlight" style={{marginTop:'20px'}}>
                        <tbody>
                        <tr>
                            <td>
                                <strong>Éléments à examiner durant le prochain audit</strong>
                            </td>
                            <td>{this.state.conclusion.nextAuditExaminedElement}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Date du premier jour du prochain audit</strong>
                            </td>
                            <td><Moment
                              format="DD/MM/YYYY">{new Date(this.state.conclusion.nextAuditFirstDaysDate)}</Moment>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Lieu, date, nom du responsable d'audit</strong>
                            </td>
                            <td>{this.state.conclusion.locationDateManager}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Card>
            </div>
        );
    }
}

export default ReportPdfViewer;
