import React, {Component} from 'react';
import {TextInput, Button, Col, Row} from 'react-materialize';
import {createBrowserHistory} from "history";

const history = createBrowserHistory({
    forceRefresh: true
});
const sha256 = require('sha256');

class LoginFormOrganizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {}
        };
    }

    changeUsername(event) {
        this.setState({
            user: {
                ...this.state.user,
                username: event.target.value
            }
        });
    }

    changePassword(event) {
        this.setState({
            user: {
                ...this.state.user,
                password: event.target.value
            }
        });
    }

    login(event) {
        event.preventDefault();
        fetch('/server/login/organization', {
            method: 'POST',
            body: JSON.stringify({
                username: this.state.user.username,
                password: sha256(this.state.user.password)
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    history.replace('/');
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
                console.error(err);
                alert('Error logging in please try again');
            });
    }

    render() {
        return (
            <div>
                <form onSubmit={(event) => this.login(event)}>
                    <Row>
                        <Col m={12} s={12}>
                            <TextInput m={12} s={12} label="Username" value={this.state.user.username} validate required
                                       onChange={(event) => this.changeUsername(event)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col m={12} s={12}>
                            <TextInput m={12} s={12} label="Password" value={this.state.user.password} validate required
                                       password
                                       onChange={(event) => this.changePassword(event)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col m={12} s={12}>
                            <Button className="blue" onClick={(event) => this.login(event)}>Connexion</Button>
                        </Col>
                    </Row>
                </form>

            </div>
        );
    }
}

export default LoginFormOrganizations;
