import React, {Component} from 'react';
import './AuditorsList.css'
import {Row, Col, Collection, CollectionItem, Card, Modal, Button, Icon} from 'react-materialize';
import {ToastContainer} from 'react-toastify';
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');

const cookies = new Cookies();

class AuditorsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditors: [],
            cookie: jwt.decode(cookies.get('token'))
        };
    }

    componentDidMount() {
        this.getAuditors();
    }

    getAuditors() {
        fetch(`/server/auditors/${this.state.cookie.pk_auditor}`)
            .then(res => res.json())
            .then(auditors =>
                this.setState({auditors}));
    }

    deleteAuditor(pk_auditor) {
        fetch(`/server/auditors/delete/${pk_auditor}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getAuditors();
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Card>
                            <Collection>
                                {this.state.auditors.map(auditor =>
                                    <CollectionItem className="avatar" key={auditor.pk_auditor}>
                                        <a href={`/Auditor/${auditor.pk_auditor}`}><span
                                            className="title">{auditor.firstName} {auditor.lastName}</span>
                                            <p>
                                                {auditor.email}
                                                <br/>
                                                {auditor.phone}
                                            </p>
                                        </a>
                                        <Modal trigger={<Button className="secondary-content red">
                                            <Icon className="white-text">delete</Icon>
                                        </Button>}>
                                            <div className="center-align">
                                                <h5>Voulez-vous vraiment supprimer cet auditeur ?</h5>
                                                <Button className="green center-align btn-modal"
                                                        onClick={() => this.deleteAuditor(auditor.pk_auditor)}>Confirmer</Button>
                                                <Button className="red center-align btn-modal"
                                                        modal="close">Annuler</Button>
                                            </div>
                                        </Modal>
                                    </CollectionItem>
                                )}

                            </Collection>
                            <ToastContainer position="bottom-center" hideProgressBar="true"/>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AuditorsList;
