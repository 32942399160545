import React, {Component} from 'react';
import {Button, Card, Select, TextInput, Textarea} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class NewReportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: {
                fk_organization: this.props.id,
                fk_auditType: 1,
                fk_last_auditType: 1,
                fk_auditor_auditManager: 1
            },
            auditors: [],
            message: {},
            auditTypes: []
        }
    }

    componentDidMount() {
        fetch('/server/auditTypes')
            .then(res => res.json())
            .then(auditTypes =>
                this.setState({auditTypes}));
        fetch('/server/auditors')
            .then(res => res.json())
            .then(auditors =>
                this.setState({auditors}));
    }

    changeName(event) {
        this.setState({
            report: {
                ...this.state.report,
                name: event.target.value
            }
        });
    };

    changeOrganizationChangesSinceLastAudit(event) {
        this.setState({
            report: {
                ...this.state.report,
                organizationChangesSinceLastAudit: event.target.value
            }
        });
    };

    changeAuditPreparation(event) {
        this.setState({
            report: {
                ...this.state.report,
                auditPreparation: event.target.value
            }
        });
    };

    changeAuditMethod(event) {
        this.setState({
            report: {
                ...this.state.report,
                auditMethod: event.target.value
            }
        });
    }

    changeAuditScope(event) {
        this.setState({
            report: {
                ...this.state.report,
                auditScope: event.target.value
            }
        });
    }

    changeAuditType(event) {
        this.setState({
            report: {
                ...this.state.report,
                fk_auditType: event.target.value
            }
        });
    }

    changeLastAuditType(event) {
        this.setState({
            report: {
                ...this.state.report,
                fk_last_auditType: event.target.value
            }
        });
    }

    changeAuditorAuditManager(event) {
        this.setState({
            report: {
                ...this.state.report,
                fk_auditor_auditManager: event.target.value
            }
        });
    }

    addReport() {
        fetch('/server/reports/add', {
            method: 'PUT',
            body: JSON.stringify(this.state.report),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.created) {
                    this.setState({
                        auditor: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    };

    render() {
        return (
            <div>
                <Card>
                    <TextInput label="Nom rapport" value={this.state.report.name} validate required
                               onChange={(event) => this.changeName(event)}/>
                    <Select className="select-collection" label="Auditeur lead"
                            value={this.state.report.fk_auditor_auditManager} s={12} m={12}
                            onChange={(event) => this.changeAuditorAuditManager(event)}>
                        {this.state.auditors.map((auditor, i) =>
                            <option value={auditor.pk_auditor}>
                                {auditor.firstName} {auditor.lastName}
                            </option>
                        )}
                    </Select>
                    <TextInput label="Changement depuis dernier audit"
                               value={this.state.report.organizationChangesSinceLastAudit} validate required
                               onChange={(event) => this.changeOrganizationChangesSinceLastAudit(event)}/>
                    <TextInput label="Préparation d'audit" value={this.state.report.auditPreparation} validate required
                               onChange={(event) => this.changeAuditPreparation(event)}/>
                    <Textarea label="Méthode d'audit" value={this.state.report.auditMethod} validate required
                               onChange={(event) => this.changeAuditMethod(event)}/>
                    <TextInput label="Scope" type="number" value={this.state.report.auditScope} validate required
                               onChange={(event) => this.changeAuditScope(event)}/>
                    <Select className="select-collection" label="Type d'audit"
                            value={this.state.report.fk_auditType} s={12} m={12}
                            onChange={(event) => this.changeAuditType(event)}>
                        {this.state.auditTypes.map((auditType, i) =>
                            <option value={auditType.pk_auditType}>
                                {auditType.name}
                            </option>
                        )}
                    </Select>
                    <Select className="select-collection" label="Type de l'audit précédente"
                            value={this.state.report.fk_last_auditType} s={12} m={12}
                            onChange={(event) => this.changeLastAuditType(event)}>
                        {this.state.auditTypes.map((auditType, i) =>
                            <option value={auditType.pk_auditType}>
                                {auditType.name}
                            </option>
                        )}
                    </Select>
                    <Button
                        floating
                        fab
                        icon="save"
                        className="green"
                        large
                        wave="light"
                        tooltip="Ajouter"
                        tooltipOptions={{position: 'left'}}
                        onClick={() => this.addReport()}
                    >
                    </Button>
                </Card>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default NewReportForm;
