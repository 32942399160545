import React, {Component} from 'react';
import {Button, Icon, TextInput, DatePicker, Row, Col, Collection, CollectionItem, Select, Textarea} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";
import { Text } from '@react-pdf/renderer'

class ReportInfoEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportData: {},
            auditDates: [],
            reportScopes: [],
            scopes: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getReportData();
        this.getScopes();
    }

    getReportData() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}`)
            .then(res => res.json())
            .then(reportData => {
                this.setState({reportData});
                this.setState({auditDates: reportData.auditDates});
                this.setState({reportScopes: reportData.scopes});
            })
            .then(() => {
                this.setState(state => {
                    const reportScopes = state.reportScopes.map((item, j) => {
                        return {
                            pk_reportScope: item.reportScope.pk_reportScope,
                            fk_report: item.reportScope.fk_report,
                            fk_scope: item.reportScope.fk_scope,
                            createdAt: item.reportScope.createdAt,
                            updatedAt: item.reportScope.updatedAt
                        };
                    });

                    return {
                        reportScopes
                    };
                });
            });
    }

    getScopes() {
        fetch('/server/scopes')
            .then(res => res.json())
            .then(scopes =>
                this.setState({scopes}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(0);
        }
    }

    changeName(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                name: event.target.value
            }
        });
    };

    changeOrganizationChangesSinceLastAudit(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                organizationChangesSinceLastAudit: event.target.value
            }
        });
    };

    changeAuditPreparation(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                auditPreparation: event.target.value
            }
        });
    };

    changeAuditMethod(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                auditMethod: event.target.value
            }
        });
    }

    changeStandards(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                standards: event.target.value
            }
        });
    }

    changeOtherStandards(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                otherStandards: event.target.value
            }
        });
    }

    changeCertificationBody(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                certificationBody: event.target.value
            }
        });
    }

    changeManagementSystemChangesSinceLastAudit(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                managementSystemChangesSinceLastAudit: event.target.value
            }
        });
    }

    changeAuditFrequency(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                auditFrequency: event.target.value
            }
        });
    }

    changeNumberAuditDays(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                numberAuditDays: event.target.value
            }
        });
    }

    changeAuditSiteDuration(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                auditSiteDuration: event.target.value
            }
        });
    }

    changeAuditScope(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                auditScope: event.target.value
            }
        });
    }

    changeExcludedProductsAndActivities(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                excludedProductsAndActivities: event.target.value
            }
        });
    }

    changeApplicationDomain(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                applicationDomain: event.target.value
            }
        });
    }

    changeOutsourcedProcessOrProducts(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                outsourcedProcessOrProducts: event.target.value
            }
        });
    }

    changeStandardExclusion(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                standardExclusion: event.target.value
            }
        });
    }

    changeReferenceDocuments(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            reportData: {
                ...this.state.reportData,
                referenceDocuments: event.target.value
            }
        });
    }

    changeAuditDate(date, i) {
        this.checkDataAlreadyChanged();

        const newAuditDate = new Date(date);
        this.setState(state => {
            const auditDates = state.auditDates.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        auditDate: new Date(newAuditDate.getFullYear(), newAuditDate.getMonth(), newAuditDate.getDate(),
                            newAuditDate.getHours(), newAuditDate.getMinutes())
                    };
                } else {
                    return item;
                }
            });

            return {
                auditDates
            };
        });
        console.log(this.state.auditDates);
    }

    addAuditDate() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const auditDates = state.auditDates.concat({
                auditDate: new Date(),
                fk_report: id
            });

            return {
                auditDates
            };
        });
        console.log(this.state.auditDates);
    }

    delAuditDate(i) {
        this.checkDataAlreadyChanged();

        this.setState(state => {
            const auditDates = state.auditDates.filter((item, j) => i !== j);
            return {
                auditDates
            };
        });
    }

    changeScope(event, i) {
        this.checkDataAlreadyChanged();

        const newScope = event.target.value;
        this.setState(state => {
            const reportScopes = state.reportScopes.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        fk_scope: newScope
                    };
                } else {
                    return item;
                }
            });

            return {
                reportScopes
            };
        });
    }

    addScope() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const reportScopes = state.reportScopes.concat({
                fk_report: id,
                fk_scope: 1
            });

            return {
                reportScopes
            };
        });
    }

    delScope(i) {
        this.checkDataAlreadyChanged();

        this.setState(state => {
            const reportScopes = state.reportScopes.filter((item, j) => i !== j);
            return {
                reportScopes
            };
        });
    }

    updateReportData() {
        const {id} = this.props.id;
        fetch(`/server/reports/update/${id}`, {
            method: 'POST',
            body: JSON.stringify(this.state.reportData),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    this.setState({
                        auditor: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/${id}/auditDates`, {
            method: 'POST',
            body: JSON.stringify(this.state.auditDates),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    this.setState({
                        auditor: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/${id}/reportScopes`, {
            method: 'POST',
            body: JSON.stringify(this.state.reportScopes),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    this.setState({
                        auditor: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });

        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    }

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getReportData();
        this.getScopes();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Collection className="z-depth-1">
                            <CollectionItem>
                                <TextInput s={12} m={12} value={this.state.reportData.name} label="Nom rapport"
                                           required
                                           onChange={(event) => this.changeName(event)}/>
                                <Col>
                                    <Row>
                                        <h5 className="left-align">Dates d'audit</h5>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addAuditDate()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                </Col>

                                {this.state.auditDates.map((auditDate, i) =>
                                    <Row>
                                        <Col m={12} s={12}>
                                            <Collection className="z-depth-1">
                                                <CollectionItem>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delAuditDate(i)}><Icon>clear</Icon></Button>
                                                    <DatePicker s={12} m={12}
                                                                options={{
                                                                    defaultDate: new Date(auditDate.auditDate),
                                                                    setDefaultDate: true,
                                                                    format: 'dd/mm/yyyy'
                                                                }}
                                                                label="Date d'audit"
                                                                icon="date_range"
                                                                onChange={(date) => this.changeAuditDate(date, i)}/>
                                                </CollectionItem>
                                            </Collection>
                                        </Col>
                                    </Row>
                                )}
                                <Textarea s={12} m={12} value={this.state.reportData.organizationChangesSinceLastAudit}
                                           label="Principaux changements au sein de l'organisation depuis le dernier audit"
                                           onChange={(event) => this.changeOrganizationChangesSinceLastAudit(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.auditPreparation}
                                           label="Préparation de l’audit"
                                           onChange={(event) => this.changeAuditPreparation(event)}/>
                                <Textarea s={12} m={12} value={this.state.reportData.auditMethod}
                                           label="Méthode d'audit"
                                           onChange={(event) => this.changeAuditMethod(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.standards}
                                           label="Norme(s) et version"
                                           onChange={(event) => this.changeStandards(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.otherStandards}
                                           label="Autres normes certifiées"
                                           onChange={(event) => this.changeOtherStandards(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.certificationBody}
                                           label="Organisme certificateur"
                                           onChange={(event) => this.changeCertificationBody(event)}/>
                                <Textarea s={12} m={12}
                                           value={this.state.reportData.managementSystemChangesSinceLastAudit}
                                           label="Principaux changements au sein du système de management depuis le dernier audit"
                                           onChange={(event) => this.changeManagementSystemChangesSinceLastAudit(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.auditFrequency}
                                           label="Fréquence d'audit"
                                           onChange={(event) => this.changeAuditFrequency(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.numberAuditDays}
                                           label="Nombre de jours d'audit" type="number" min="0"
                                           onChange={(event) => this.changeNumberAuditDays(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.auditSiteDuration}
                                           label="Durée de l'audit sur site (h)" type="number" min="0"
                                           onChange={(event) => this.changeAuditSiteDuration(event)}/>
                                <Textarea s={12} m={12} value={this.state.reportData.excludedProductsAndActivities}
                                           label="Produits et activités exclus du périmètre de certification"
                                           onChange={(event) => this.changeExcludedProductsAndActivities(event)}/>
                                <Textarea s={12} m={12} value={this.state.reportData.applicationDomain}
                                           label="Domaine d’application"
                                           onChange={(event) => this.changeApplicationDomain(event)}/>
                                <Textarea s={12} m={12} value={this.state.reportData.outsourcedProcessOrProducts}
                                           label="Processus ou produits externalisés"
                                           onChange={(event) => this.changeOutsourcedProcessOrProducts(event)}/>
                                <TextInput s={12} m={12} value={this.state.reportData.standardExclusion}
                                           label="Exclusions de la norme"
                                           onChange={(event) => this.changeStandardExclusion(event)}/>
                                <Textarea s={12} m={12} value={this.state.reportData.referenceDocuments}
                                           label="Documents de référence"
                                           onChange={(event) => this.changeReferenceDocuments(event)}/>
                                <Col>
                                    <Row>
                                        <h5 className="left-align">Scopes</h5>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addScope()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                </Col>

                                {this.state.reportScopes.map((reportScope, i) =>
                                    <div>
                                        <Button flat waves="light" className="red-text right"
                                                onClick={() => this.delScope(i)}><Icon>clear</Icon></Button>
                                        <Select className="select-collection" label="Scope"
                                                value={reportScope.fk_scope} s={12} m={12}
                                                onChange={(event) => this.changeScope(event, i)}>
                                            {this.state.scopes.map((scope, i) =>
                                                <option value={scope.pk_scope}>
                                                    {scope.pk_scope} - {scope.name}
                                                </option>
                                            )}
                                        </Select>
                                    </div>
                                )}
                            </CollectionItem>
                        </Collection>
                    </Col>
                </Row>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateReportData()}/>
                    }

                </Button>
            </div>
        );
    }
}

export default ReportInfoEdit;
