import React, {Component} from 'react';
import {
    Row,
    Col,
    Button,
    Card,
    Icon,
    Collection,
    CollectionItem,
    Modal,
    Table,
    Checkbox,
    Textarea
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class ChecklistReportEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportChecklists: [],
            checklists: [],
            questions: [],
            questionsUpdate: [],
            otherChecklists: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getChecklists();
    }

    getChecklists() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}`)
            .then(res => res.json())
            .then(reportData =>
                this.setState({reportChecklists: reportData.checklists}));
        fetch(`/server/reports/${id}/otherChecklists`)
            .then(res => res.json())
            .then(otherChecklists =>
                this.setState({otherChecklists}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
        }
    }

    changeYes(event, i) {
        this.checkDataAlreadyChanged();

        const newYes = event.target.checked;
        this.setState(state => {
            const questionsUpdate = state.questionsUpdate.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        yes: newYes
                    };
                } else {
                    return item;
                }
            });

            return {
                questionsUpdate
            };
        });
    }

    changeComment(event, i) {
        this.checkDataAlreadyChanged();
        const newComment = event.target.value;
        this.setState(state => {
            const questionsUpdate = state.questionsUpdate.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        comment: newComment
                    };
                } else {
                    return item;
                }
            });

            return {
                questionsUpdate
            };
        });
    }

    addReportChecklist(checklistId) {
        const {id} = this.props.id;
        fetch(`/server/reports/add/${id}/checklist/${checklistId}`, {
            method: 'PUT',
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                this.getChecklists();
                if (this.state.message.created) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    }

    delReportChecklist(checklistId) {
        const {id} = this.props.id;
        fetch(`/server/reports/delete/${id}/checklist/${checklistId}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getChecklists();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    udpateQuestions() {
        console.log("YES");
        fetch(`/server/reports/update/checklist/questions`, {
            method: 'POST',
            body: JSON.stringify(this.state.questionsUpdate),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });

        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    };

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getChecklists();
    }

    getQuestions = (checklist) => {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}/checklists/${checklist.id}`)
            .then(res => res.json())
            .then(checklist => {
                this.setState({questions: checklist.questions});
                fetch(`/server/reports/reportChecklistQuestions/${checklist.pk_report_checklist}`)
                    .then(res => res.json())
                    .then(questions => this.setState({questionsUpdate: questions}));
            });
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    };

    render() {
        return (
            <div>
                <Card>
                    <Row>
                        <Col m={12} s={12}>
                            <Modal header="Autres Checklists"
                                   trigger={<Button className="blue"><Icon>playlist_add</Icon> Ajouter
                                       checklists</Button>}>
                                {this.state.otherChecklists.map((otherChecklist, i) =>
                                    <Card>
                                        {otherChecklist.name}
                                        <Button className="right green"
                                                onClick={() => this.addReportChecklist(otherChecklist.pk_checklist)}><Icon>add</Icon></Button>
                                    </Card>
                                )}

                            </Modal>
                            <Collection header="Checklists du rapport">
                                {this.state.reportChecklists.map((reportChecklist, i) =>

                                    <CollectionItem>
                                        {reportChecklist.name}
                                        <Button flat waves="light" className="red-text secondary-content"
                                                onClick={() => this.delReportChecklist(reportChecklist.pk_checklist)}><Icon>clear</Icon>
                                        </Button>
                                        <Modal id={reportChecklist.pk_checklist} header="Questions" options={{
                                            onOpenStart: this.getQuestions
                                        }} trigger={
                                            <Button flat waves="light" className="blue-text secondary-content">
                                                <Icon>edit</Icon>
                                            </Button>
                                        }>
                                            <Card>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th data-field="question">
                                                            Question
                                                        </th>
                                                        <th data-field="yes">
                                                            Oui
                                                        </th>
                                                        <th data-field="comment">
                                                            Commentaire
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.questionsUpdate.map((question, i) =>
                                                        <tr>
                                                            {this.state.questions[i] &&
                                                            <td>
                                                                <pre style={{ whiteSpace:'pre-wrap', wordWrap:'break-word'}}>{this.state.questions[i].question}</pre>
                                                            </td>
                                                            }
                                                            <td><Checkbox
                                                                checked={question.yes}
                                                                onChange={(event) => this.changeYes(event, i)}/>
                                                            </td>
                                                            <td><Textarea s={12} m={12}
                                                                          value={question.comment}
                                                                          label="Commentaire" validate
                                                                          onChange={(event) => this.changeComment(event, i)}/>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </Table>
                                                <div className="center-align">
                                                    <Button className="green center-align btn-modal" modal="close"
                                                            onClick={() => this.udpateQuestions()}
                                                            disabled={!this.state.dataChanged}>Sauvegarder</Button>
                                                    <Button className="red center-align btn-modal"
                                                            modal="close">Annuler</Button>
                                                </div>
                                            </Card>
                                        </Modal>
                                    </CollectionItem>
                                )}
                            </Collection>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default ChecklistReportEdit;
