import React, {Component} from 'react';
import './AddChecklist.css';
import NewChecklistForm from "../../components/NewChecklistForm/NewChecklistForm";
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const history = createBrowserHistory({
    forceRefresh: true
});
const cookies = new Cookies();

class AddChecklist extends Component {

    render() {
        if (jwt.decode(cookies.get('token')).isAdmin !== 1)
            history.push('/');
        return (
            <div>
                <NewChecklistForm/>
            </div>
        );
    }
}

export default AddChecklist;
