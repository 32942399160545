import React, {Component} from 'react';
import LoginForm from "../../components/LoginForm/LoginForm";
import {createBrowserHistory} from "history";

const history = createBrowserHistory({
    forceRefresh: true
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        fetch('/server/checkToken')
            .then(res => {
                if (res.status === 200) {
                    history.push('/');
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <div>
                <LoginForm/>
            </div>
        );
    }
}

export default Login;
