import React, {Component} from 'react';
import './OrganizationInfoEdit.css';
import {TextInput, Textarea, Button} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";
import {Redirect} from 'react-router-dom';

class OrganizationInfoEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organization: {},
            message: {},
            fireRedirect: false,
            urlParam: ''
        };
    }

    componentDidMount() {
        const {id} = this.props.id;
        fetch(`/server/organizations/${id}`)
            .then(res => res.json())
            .then(organization =>
                this.setState({organization}));
    }

    changeName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                name: event.target.value
            }
        });
    };

    changeEmail(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                email: event.target.value
            }
        });
    };

    changePhone(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                phone: event.target.value
            }
        });
    };

    changeWebsite(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                website: event.target.value
            }
        });
    }

    changeBranches(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                branches: event.target.value
            }
        });
    }

    changeContactLastName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                contactLastName: event.target.value
            }
        });
    }

    changeContactFirstName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                contactFirstName: event.target.value
            }
        });
    }

    changeGeneralDescription(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                generalDescription: event.target.value
            }
        });
    }

    changeOutsourcedProcessProductsDescription(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                outsourcedProcessProductsDescription: event.target.value
            }
        });
    }

    changeHeadquartersName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                headquartersName: event.target.value
            }
        });
    }

    changeHeadquartersNP(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                headquartersNP: event.target.value
            }
        });
    }

    changeHeadquartersAddress(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                headquartersAddress: event.target.value
            }
        });
    }

    changeHeadquartersLocation(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                headquartersLocation: event.target.value
            }
        });
    }

    changeEmployeesNumber(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                employeesNumber: event.target.value
            }
        });
    }

    changeFullTimeNumber(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                fullTimeNumber: event.target.value
            }
        });
    }

    updateOrganization() {
        const {id} = this.props.id;
        fetch(`/server/organizations/update/${id}`, {
            method: 'POST',
            body: JSON.stringify(this.state.organization),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    this.setState({
                        auditor: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    };

    deleteOrganization() {
        const {id} = this.props.id;
        fetch(`/server/organizations/delete/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'success'
                })
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    };

    render() {
        return (
            <div>
                <TextInput s={12} m={12} value={this.state.organization.name}
                           label="Nom de l'organisation du site audité"
                           onChange={(event) => this.changeName(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.email} label="Email" email validate
                           onChange={(event) => this.changeEmail(event)}/>
                < TextInput s={12} m={12} value={this.state.organization.phone} label="Téléphone"
                            onChange={(event) => this.changePhone(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.website} label="Site Web"
                           onChange={(event) => this.changeWebsite(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.branches}
                           label="Branches / Etablissements secondaires / Processus externalisés"
                           onChange={(event) => this.changeBranches(event)}/>

                <TextInput s={12} m={12} value={this.state.organization.contactLastName}
                           label="Nom de la personne de contact"
                           onChange={(event) => this.changeContactLastName(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.contactFirstName}
                           label="Prénom de la personne de contact"
                           onChange={(event) => this.changeContactFirstName(event)}/>
                <Textarea s={12} m={12} value={this.state.organization.generalDescription}
                           label="Description générale de l'organisation"
                           onChange={(event) => this.changeGeneralDescription(event)}/>
                <Textarea s={12} m={12} value={this.state.organization.outsourcedProcessProductsDescription}
                           label="Brève description des processus et produits exclus et justification de l'exclusion"
                           onChange={(event) => this.changeOutsourcedProcessProductsDescription(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.headquartersName}
                           label="Nom du siège de l'organisation"
                           onChange={(event) => this.changeHeadquartersName(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.headquartersNP}
                           label="NP du siège"
                           onChange={(event) => this.changeHeadquartersNP(event)} type="number"/>
                <TextInput s={12} m={12} value={this.state.organization.headquartersAddress}
                           label="Adresse du Siège"
                           onChange={(event) => this.changeHeadquartersAddress(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.headquartersLocation} label="Ville du Siège"
                           onChange={(event) => this.changeHeadquartersLocation(event)}/>
                <TextInput s={12} m={12} value={this.state.organization.employeesNumber}
                           label="Nombre total d'employés"
                           onChange={(event) => this.changeEmployeesNumber(event)} type="number"/>
                <TextInput s={12} m={12} value={this.state.organization.fullTimeNumber}
                           label="Nombre d'équivalents plein temps"
                           onChange={(event) => this.changeFullTimeNumber(event)} type="number"/>
                <br/>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                >
                    <Button waves="light" floating icon="delete" className="red" tooltip="Supprimer"
                            tooltipOptions={{position: 'left'}} onClick={() => this.deleteOrganization()}/>
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateOrganization()}/>
                </Button>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
                {this.state.fireRedirect && (
                    <Redirect to={`/organizations?${this.state.urlParam}`}/>
                )}
            </div>
        );
    }
}

export default OrganizationInfoEdit;
