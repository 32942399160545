import React, {Component} from 'react';
import {
    Button,
    Card,
    Select,
    CollectionItem,
    Collection, Icon, Modal
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";
import {Redirect} from 'react-router-dom';

class AuditorEditPermissions extends Component {
    constructor(props) {
        super(props);
        const {id} = this.props.id;
        this.state = {
            isAdmin: false,
            organizations: [],
            otherOrganizations: [],
            permissions: [],
            newPermission: {
                fk_permission: 1,
                fk_auditor: id
            },
            message: {},
            fireRedirect: false,
            urlParam: '',
            dataChanged: false
        };
    }

    componentDidMount() {
        fetch('/server/permissions')
            .then(res => res.json())
            .then(permissions =>
                this.setState({permissions}));
        this.getOrganizations();
    }

    getOrganizations() {
        const {id} = this.props.id;
        fetch(`/server/auditors/auditor/${id}`)
            .then(res => res.json())
            .then(auditor =>
                this.setState({
                    isAdmin: auditor.isAdmin,
                    organizations: auditor.organizations
                }));
        fetch(`/server/auditors/${id}/otherOrganizations`)
            .then(res => res.json())
            .then(otherOrganizations =>
                this.setState({otherOrganizations}));
    }

    changePermission(event) {
        this.setState({
            newPermission: {
                ...this.state.newPermission,
                fk_permission: event.target.value
            }
        });
    }

    addOrganization(organizationId) {
        fetch(`/server/auditors/add/organization/${organizationId}`, {
            method: 'PUT',
            body: JSON.stringify(this.state.newPermission),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                this.getOrganizations();
                if (this.state.message.created) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    }

    delOrganization(organizationId) {
        const {id} = this.props.id;
        fetch(`/server/auditors/delete/${id}/${organizationId}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getOrganizations();
            })
            .catch(() => {
            })
    }

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getOrganizations();
    }

    render() {
        return (
            <div>
                <Collection className="z-depth-1">
                    <CollectionItem>
                        {this.state.isAdmin ?
                            <h6>Cet auditeur a accès à toutes les organisations car il est administrateur</h6>
                            :
                            <div>
                                <Modal header="Autres organisations"
                                       trigger={<Button className="blue"><Icon>playlist_add</Icon> Ajouter
                                           organisation</Button>}>
                                    <Select className="select-collection" label="Permission"
                                            value={this.state.newPermission.pk_permission} s={12} m={12}
                                            onChange={(event) => this.changePermission(event)}>
                                        {this.state.permissions.map((permission, i) =>
                                            <option value={permission.pk_permission}>
                                                {permission.name}
                                            </option>
                                        )}
                                    </Select>
                                    {this.state.otherOrganizations.map((otherOrganization, i) =>
                                        <Card>
                                            {otherOrganization.name}
                                            <Button className="right green" modal="close"
                                                    onClick={() => this.addOrganization(otherOrganization.pk_organization)}><Icon>add</Icon></Button>
                                        </Card>
                                    )}

                                </Modal>
                                <Collection header="Organisations liées à l'auditeur">
                                    {this.state.organizations.map((organization, i) =>

                                        <CollectionItem>
                                            {organization.name} ({this.state.permissions[organization.auditor_organization.fk_permission - 1].name})
                                            <Button flat waves="light" className="red-text secondary-content"
                                                    onClick={() => this.delOrganization(organization.pk_organization)}><Icon>clear</Icon>
                                            </Button>
                                        </CollectionItem>
                                    )}
                                </Collection>
                            </div>
                        }
                    </CollectionItem>
                </Collection>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
                {this.state.fireRedirect && (
                    <Redirect to={`/auditors?${this.state.urlParam}`}/>
                )}
            </div>
        );
    }
}

export default AuditorEditPermissions;
