import React, {Component} from 'react';
import './App.css';
import Main from "./components/Main";
import Header from "./components/Header/Header";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: false
        }
    }

    componentDidMount() {
        fetch('/server/checkToken')
            .then(res => {
                if (res.status === 200) {
                    this.setState({isLogged: true});
                } else {
                    this.setState({isLogged: false});
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({isLogged: false});
            });
    }

    render() {
        return (
            <div className="App main">
                {this.state.isLogged &&
                <Header/>
                }
                <div className="body">
                    <Main/>
                </div>
            </div>
        );
    }
}

export default App;
