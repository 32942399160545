import React, {Component} from 'react';
import {Row, Col, Collection, CollectionItem, Card, Icon, Button, Modal} from 'react-materialize';
import FabAddPage from "../FabAddPage/FabAddPage";

class OrganizationUsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        const {id} = this.props.id;
        fetch(`/server/organizations/${id}/users`)
            .then(res => res.json())
            .then(users =>
                this.setState({users}));
    }

    deleteUser(pk_organizationUser) {
        const {id} = this.props.id;
        fetch(`/server/organizations/delete/${id}/user/${pk_organizationUser}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getUsers();
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    }

    render() {
        const {id} = this.props.id;
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Card>
                            <Collection>
                                {this.state.users.map(user =>
                                    <CollectionItem className="avatar" key={user.pk_organizationUser}>
                                        <a href={`/organizationUser/${user.pk_organizationUser}`}><span
                                            className="title">{user.firstName} {user.lastName}</span>
                                            <p>
                                                {user.username}
                                                <br/>
                                                {user.email}
                                            </p>
                                        </a>
                                        <Modal trigger={<Button className="secondary-content red">
                                            <Icon className="white-text">delete</Icon>
                                        </Button>}>
                                            <div className="center-align">
                                                <h5>Voulez-vous vraiment supprimer cet utilisateur ?</h5>
                                                <Button className="green center-align btn-modal"
                                                        onClick={() => this.deleteUser(user.pk_organizationUser)}>Confirmer</Button>
                                                <Button className="red center-align btn-modal"
                                                        modal="close">Annuler</Button>
                                            </div>
                                        </Modal>
                                    </CollectionItem>
                                )}
                            </Collection>
                            {this.state.users.length === 0 &&
                            <p>Aucun utilisateur</p>}
                        </Card>
                    </Col>
                </Row>
                <FabAddPage link={`/addOrganizationUser/${id}`}/>
            </div>
        );
    }
}

export default OrganizationUsersList;
