import React, {Component} from 'react';
import './GeneralFeelingEdit.css';
import {
    Icon,
    Card,
    TextInput,
    Col,
    Collection,
    CollectionItem,
    Row, Button
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class GeneralFeelingEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gfms: [],
            gfm: [],
            gfperf: [],
            gfprod: [],
            gfr: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getGf();
    }

    getGf() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}/gfms`)
            .then(res => res.json())
            .then(gfms =>
                this.setState({gfms}));
        fetch(`/server/reports/${id}/gfm`)
            .then(res => res.json())
            .then(gfm =>
                this.setState({gfm}));
        fetch(`/server/reports/${id}/gfperf`)
            .then(res => res.json())
            .then(gfperf =>
                this.setState({gfperf}));
        fetch(`/server/reports/${id}/gfprod`)
            .then(res => res.json())
            .then(gfprod =>
                this.setState({gfprod}));
        fetch(`/server/reports/${id}/gfr`)
            .then(res => res.json())
            .then(gfr =>
                this.setState({gfr}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(4);
        }
    }

    changeGfms(event, i) {
        this.checkDataAlreadyChanged();

        const newContent = event.target.value;
        this.setState(state => {
            const gfms = state.gfms.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        content: newContent
                    };
                } else {
                    return item;
                }
            });

            return {
                gfms
            };
        });
    }

    changeGfm(event, i) {
        this.checkDataAlreadyChanged();

        const newContent = event.target.value;
        this.setState(state => {
            const gfm = state.gfm.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        content: newContent
                    };
                } else {
                    return item;
                }
            });

            return {
                gfm
            };
        });
    }

    changeGfr(event, i) {
        this.checkDataAlreadyChanged();

        const newContent = event.target.value;
        this.setState(state => {
            const gfr = state.gfr.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        content: newContent
                    };
                } else {
                    return item;
                }
            });

            return {
                gfr
            };
        });
    }

    changeGfprod(event, i) {
        this.checkDataAlreadyChanged();

        const newContent = event.target.value;
        this.setState(state => {
            const gfprod = state.gfprod.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        content: newContent
                    };
                } else {
                    return item;
                }
            });

            return {
                gfprod
            };
        });
    }

    changeGfperf(event, i) {
        this.checkDataAlreadyChanged();

        const newContent = event.target.value;
        this.setState(state => {
            const gfperf = state.gfperf.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        content: newContent
                    };
                } else {
                    return item;
                }
            });

            return {
                gfperf
            };
        });
    }

    addGfms() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const gfms = state.gfms.concat({
                content: "",
                fk_report: id
            });

            return {
                gfms
            };
        });
    }

    addGfm() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const gfm = state.gfm.concat({
                content: "",
                fk_report: id
            });

            return {
                gfm
            };
        });
    }

    addGfperf() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const gfperf = state.gfperf.concat({
                content: "",
                fk_report: id
            });

            return {
                gfperf
            };
        });
    }

    addGfprod() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const gfprod = state.gfprod.concat({
                content: "",
                fk_report: id
            });

            return {
                gfprod
            };
        });
    }

    addGfr() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const gfr = state.gfr.concat({
                content: "",
                fk_report: id
            });

            return {
                gfr
            };
        });
    }

    delGfms(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const gfms = state.gfms.filter((item, j) => i !== j);
            return {
                gfms
            };
        });
    }

    delGfm(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const gfm = state.gfm.filter((item, j) => i !== j);
            return {
                gfm
            };
        });
    }

    delGfprod(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const gfprod = state.gfprod.filter((item, j) => i !== j);
            return {
                gfprod
            };
        });
    }

    delGfperf(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const gfperf = state.gfperf.filter((item, j) => i !== j);
            return {
                gfperf
            };
        });
    }

    delGfr(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const gfr = state.gfr.filter((item, j) => i !== j);
            return {
                gfr
            };
        });
    }

    updateGeneralFeelings() {
        const {id} = this.props.id;
        fetch(`/server/reports/update/${id}/gfms/`, {
            method: 'POST',
            body: JSON.stringify(this.state.gfms),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/${id}/gfm/`, {
            method: 'POST',
            body: JSON.stringify(this.state.gfm),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/${id}/gfprod/`, {
            method: 'POST',
            body: JSON.stringify(this.state.gfprod),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/${id}/gfperf/`, {
            method: 'POST',
            body: JSON.stringify(this.state.gfperf),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        fetch(`/server/reports/update/${id}/gfr/`, {
            method: 'POST',
            body: JSON.stringify(this.state.gfr),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    };

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getGf();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Collection className="z-depth-1">
                            <CollectionItem>
                                <Col m={12} s={12}>
                                    <Row>
                                        <h5>Impression générale – Système de management, contexte de
                                            l’organisation</h5>
                                    </Row>
                                    <Row>
                                        <h6 className="left-align"><Icon>thumb_up</Icon> Points positifs</h6>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addGfms()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                    <Row>
                                        {this.state.gfms.map((g, i) =>
                                            <Collection className="z-depth-1">
                                                <CollectionItem>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delGfms(i)}><Icon>clear</Icon></Button>
                                                    <TextInput s={12} m={12} value={g.content}
                                                               label="Point" validate
                                                               onChange={(event) => this.changeGfms(event, i)}/>
                                                </CollectionItem>
                                            </Collection>
                                        )}
                                    </Row>
                                    <h6 className="left-align"><Icon>warning</Icon> Points à améliorer</h6>
                                    <Card>
                                        Merci de vous référer aux constats d'audit
                                    </Card>
                                    <Row>
                                        <h5>Impression générale - Management, Leadership et planification</h5>
                                    </Row>
                                    <Row>
                                        <h6 className="left-align"><Icon>thumb_up</Icon> Points positifs</h6>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addGfm()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                    <Row>
                                        {this.state.gfm.map((g, i) =>
                                            <Collection className="z-depth-1">
                                                <CollectionItem>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delGfm(i)}><Icon>clear</Icon></Button>
                                                    <TextInput s={12} m={12} value={g.content}
                                                               label="Point" validate
                                                               onChange={(event) => this.changeGfm(event, i)}/>
                                                </CollectionItem>
                                            </Collection>
                                        )}
                                    </Row>
                                    <h6 className="left-align"><Icon>warning</Icon> Points à améliorer</h6>
                                    <Card>
                                        Merci de vous référer aux constats d'audit
                                    </Card>
                                    <Row>
                                        <h5>Impression générale – Ressources / support</h5>
                                    </Row>
                                    <Row>
                                        <h6 className="left-align"><Icon>thumb_up</Icon> Points positifs</h6>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addGfr()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                    <Row>
                                        {this.state.gfr.map((g, i) =>
                                            <Collection className="z-depth-1">
                                                <CollectionItem>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delGfr(i)}><Icon>clear</Icon></Button>
                                                    <TextInput s={12} m={12} value={g.content}
                                                               label="Point" validate
                                                               onChange={(event) => this.changeGfr(event, i)}/>
                                                </CollectionItem>
                                            </Collection>
                                        )}
                                    </Row>
                                    <h6 className="left-align"><Icon>warning</Icon> Points à améliorer</h6>
                                    <Card>
                                        Merci de vous référer aux constats d'audit
                                    </Card>
                                    <Row>
                                        <h5>Impression générale – Réalisation / opérations</h5>
                                    </Row>
                                    <Row>
                                        <h6 className="left-align"><Icon>thumb_up</Icon> Points positifs</h6>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addGfprod()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                    <Row>
                                        {this.state.gfprod.map((g, i) =>
                                            <Collection className="z-depth-1">
                                                <CollectionItem>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delGfprod(i)}><Icon>clear</Icon></Button>
                                                    <TextInput s={12} m={12} value={g.content}
                                                               label="Point" validate
                                                               onChange={(event) => this.changeGfprod(event, i)}/>
                                                </CollectionItem>
                                            </Collection>
                                        )}
                                    </Row>
                                    <h6 className="left-align"><Icon>warning</Icon> Points à améliorer</h6>
                                    <Card>
                                        Merci de vous référer aux constats d'audit
                                    </Card>
                                    <Row>
                                        <h5>Impression générale – Evaluations de la performance / améliorations</h5>
                                    </Row>
                                    <Row>
                                        <h6 className="left-align"><Icon>thumb_up</Icon> Points positifs</h6>
                                    </Row>
                                    <Row>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addGfperf()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                    <Row>
                                        {this.state.gfperf.map((g, i) =>
                                            <Collection className="z-depth-1">
                                                <CollectionItem>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delGfperf(i)}><Icon>clear</Icon></Button>
                                                    <TextInput s={12} m={12} value={g.content}
                                                               label="Point" validate
                                                               onChange={(event) => this.changeGfperf(event, i)}/>
                                                </CollectionItem>
                                            </Collection>
                                        )}
                                    </Row>
                                    <h6 className="left-align"><Icon>warning</Icon> Points à améliorer</h6>
                                    <Card>
                                        Merci de vous référer aux constats d'audit
                                    </Card>
                                </Col>
                            </CollectionItem>
                        </Collection>
                    </Col>
                </Row>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateGeneralFeelings()}/>
                    }

                </Button>
            </div>
        );
    }
}

export default GeneralFeelingEdit;
