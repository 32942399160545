import React, {Component} from 'react';
import './OrganizationReportsListEdit.css';
import {Row, Col, Collection, CollectionItem, Card, Icon, Button, Modal} from 'react-materialize';
import FabAddPage from "../FabAddPage/FabAddPage";

class OrganizationReportsListEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            reportData: {},
            checklists: [],
            auditPlan: {},
            auditPlanAuditors: [],
            plans: [],
            auditedPeople: [],
            gfms: [],
            gfm: [],
            gfperf: [],
            gfprod: [],
            gfr: [],
            auditReports: [],
            conclusion: {},
            conclusionComments: [],
            conclusionCompliances: [],
            conclusionNoncompliances: [],
            conclusionReviews: [],
            certificationDecision: {},
        };
    }

    componentDidMount() {
        const {id} = this.props.id;
        this.setState({
            id
        });
        this.getReports();
    }

    getReports() {
        const {id} = this.props.id;
        fetch(`/server/organizations/${id}/reports`)
            .then(res => res.json())
            .then(reports =>
                this.setState({reports}));
    }

    deleteReport(pk_report) {
        fetch(`/server/reports/delete/${pk_report}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getReports();
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Card>
                            <Collection>
                                {this.state.reports.map(report =>
                                    <CollectionItem className="avatar" key={report.pk_report}>
                                        <a href={`/Report/${report.pk_report}`}><span
                                            className="title">{report.name}</span></a>
                                        <div className="secondary-content">
                                            <Button
                                                className="blue btn-pdf"
                                                href={`/reportPdfViewer/${report.pk_report}`}
                                                node="a"
                                                waves="light">
                                                <Icon className="white-text">picture_as_pdf</Icon>
                                            </Button>
                                            <Modal trigger={<Button className=" red">
                                                <Icon className="white-text">delete</Icon>
                                            </Button>}>
                                                <div className="center-align">
                                                    <h5>Voulez-vous vraiment supprimer ce rapport ?</h5>
                                                    <Button className="green center-align btn-modal"
                                                            onClick={() => this.deleteReport(report.pk_report)}>Confirmer</Button>
                                                    <Button className="red center-align btn-modal"
                                                            modal="close">Annuler</Button>
                                                </div>
                                            </Modal>
                                        </div>
                                    </CollectionItem>
                                )}

                            </Collection>
                            {this.state.reports.length === 0 &&
                            <p>Aucun rapport</p>}
                            <FabAddPage link="/AddReport" id={this.state.id}/>
                        </Card>
                    </Col>

                </Row>

            </div>
        );
    }
}

export default OrganizationReportsListEdit;
