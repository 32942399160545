import React, {Component} from 'react';
import './Auditor.css';
import AuditorEdit from "../../components/AuditorEdit/AuditorEdit";

class Auditor extends Component {
    render() {
        return (
            <div>
                <AuditorEdit id={this.props.match.params}/>
            </div>
        );
    }
}

export default Auditor;
