import React, {Component} from 'react';
import './Checklist.css';
import ChecklistEdit from "../../components/ChecklistEdit/ChecklistEdit";
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const history = createBrowserHistory({
    forceRefresh: true
});
const cookies = new Cookies();

class Checklist extends Component {
    render() {
        if (jwt.decode(cookies.get('token')).isAdmin !== 1)
            history.push('/');
        return (
            <div>
                <ChecklistEdit id={this.props.match.params}/>
            </div>
        );
    }
}

export default Checklist;
