import React, {Component} from 'react';
import {Row, Col, Collection, CollectionItem, Card, Icon} from 'react-materialize';
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();

class OrganizationReportsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token')),
            reports: []
        };
    }

    componentDidMount() {
        const {id} = this.props.id;
        this.setState({
            id
        });
        this.getReports();
    }

    getReports() {
        const {id} = this.props.id;
        if (this.state.cookie.fk_organization)
            fetch(`/server/organizations/${this.state.cookie.fk_organization}/reports`)
                .then(res => res.json())
                .then(reports =>
                    this.setState({reports}));
        else
            fetch(`/server/organizations/${id}/reports`)
                .then(res => res.json())
                .then(reports =>
                    this.setState({reports}));
    }

    deleteReport(pk_report) {
        fetch(`/server/reports/delete/${pk_report}`, {
            method: 'DELETE'
        })
            .then(() => {
                this.getReports();
            })
            .catch(() => {
                this.setState({
                    fireRedirect: true,
                    urlParam: 'error'
                })
            })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Card>
                            <Collection>
                                {this.state.reports.map(report =>
                                    <CollectionItem className="avatar" key={report.pk_report}>
                                        <span className="title">{report.name}</span>
                                        <a className="blue btn-pdf secondary-content" waves="light"
                                           href={`/reportPdfViewer/${report.pk_report}`}>
                                            <Icon className="white-text">picture_as_pdf</Icon>
                                        </a>
                                    </CollectionItem>
                                )}
                            </Collection>
                            {this.state.reports.length === 0 &&
                            <p>Aucun rapport</p>}
                        </Card>
                    </Col>

                </Row>

            </div>
        );
    }
}

export default OrganizationReportsList;
