import React, {Component} from 'react';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    TextInput,
    Icon,
    Button, Table, Checkbox
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class AuditedPeopleEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditedPeople: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getAuditedPeople();
    }

    getAuditedPeople() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}/auditedPeople`)
            .then(res => res.json())
            .then(auditedPeople =>
                this.setState({auditedPeople}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(3);
        }
    }

    changeLastName(event, i) {
        this.checkDataAlreadyChanged();

        const newLastName = event.target.value;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        lastName: newLastName
                    };
                } else {
                    return item;
                }
            });

            return {
                auditedPeople
            };
        });
    }

    changeFirstName(event, i) {
        this.checkDataAlreadyChanged();

        const newFirstName = event.target.value;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        firstName: newFirstName
                    };
                } else {
                    return item;
                }
            });

            return {
                auditedPeople
            };
        });
    }

    changeFunction(event, i) {
        this.checkDataAlreadyChanged();

        const newFunction = event.target.value;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        function: newFunction
                    };
                } else {
                    return item;
                }
            });

            return {
                auditedPeople
            };
        });
    }

    changeOpeningMeeting(event, i) {
        this.checkDataAlreadyChanged();

        const newOpeningMeeting = event.target.checked;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        openingMeeting: newOpeningMeeting
                    };
                } else {
                    return item;
                }
            });

            return {
                auditedPeople
            };
        });
    }

    changeOnSiteAudit(event, i) {
        this.checkDataAlreadyChanged();

        const newOnSiteAudit = event.target.checked;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        onSiteAudit: newOnSiteAudit
                    };
                } else {
                    return item;
                }
            });

            return {
                auditedPeople
            };
        });
    }

    changeClosingMeeting(event, i) {
        this.checkDataAlreadyChanged();

        const newClosingMeeting = event.target.checked;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.map((item, j) => {
                if (j === i) {
                    return {
                        ...item,
                        closingMeeting: newClosingMeeting
                    };
                } else {
                    return item;
                }
            });

            return {
                auditedPeople
            };
        });
    }

    delAuditedPeople(i) {
        this.checkDataAlreadyChanged();
        this.setState(state => {
            const auditedPeople = state.auditedPeople.filter((item, j) => i !== j);
            return {
                auditedPeople
            };
        });
    }

    addAuditedPeople() {
        this.checkDataAlreadyChanged();

        const {id} = this.props.id;
        this.setState(state => {
            const auditedPeople = state.auditedPeople.concat({
                lastName: "",
                firstName: "",
                function: "",
                openingMeeting: 0,
                onSiteAudit: 0,
                closingMeeting: 0,
                fk_report: id
            });

            return {
                auditedPeople
            };
        });
    }

    updateAuditedPeople() {
        const {id} = this.props.id;
        fetch(`/server/reports/update/${id}/auditedPeople/`, {
            method: 'POST',
            body: JSON.stringify(this.state.auditedPeople),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    }

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getAuditedPeople();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Collection className="z-depth-1">
                            <CollectionItem>
                                <Col m={12} s={12}>
                                    <Row>
                                        <Table className="centered highlight hide-on-small-only">
                                            <thead>
                                            <tr>
                                                <th data-field="last-name">
                                                    Nom
                                                </th>
                                                <th data-field="first-name">
                                                    Prénom
                                                </th>
                                                <th data-field="function">
                                                    Fonction
                                                </th>
                                                <th data-field="opening-meeting">
                                                    Réunion d'ouverture
                                                </th>
                                                <th data-field="on-site-audit">
                                                    Audit sur site
                                                </th>
                                                <th data-field="closing-meeting">
                                                    Réunion de clôture
                                                </th>
                                                <th data-field="remove"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.auditedPeople.map((auditedPerson, i) =>
                                                <tr>
                                                    <td>
                                                        <TextInput s={12} m={12} value={auditedPerson.lastName}
                                                                   label="Nom" validate
                                                                   onChange={(event) => this.changeLastName(event, i)}/>
                                                    </td>
                                                    <td>
                                                        <TextInput s={12} m={12} value={auditedPerson.firstName}
                                                                   label="Prénom" validate
                                                                   onChange={(event) => this.changeFirstName(event, i)}/>
                                                    </td>
                                                    <td>
                                                        <TextInput s={12} m={12} value={auditedPerson.function}
                                                                   label="Fonction" validate
                                                                   onChange={(event) => this.changeFunction(event, i)}/>
                                                    </td>
                                                    <td>
                                                        <Checkbox checked={auditedPerson.openingMeeting}
                                                                  onChange={(event) => this.changeOpeningMeeting(event, i)}/>
                                                    </td>
                                                    <td>
                                                        <Checkbox checked={auditedPerson.onSiteAudit}
                                                                  onChange={(event) => this.changeOnSiteAudit(event, i)}/>
                                                    </td>
                                                    <td>
                                                        <Checkbox checked={auditedPerson.closingMeeting}
                                                                  onChange={(event) => this.changeClosingMeeting(event, i)}/>
                                                    </td>
                                                    <td>
                                                        <Button flat waves="light" className="red-text right"
                                                                onClick={() => this.delAuditedPeople(i)}><Icon>clear</Icon></Button>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </Table>
                                        <Collection
                                            className="z-depth-1 hide-on-med-only hide-on-large-only hide-on-extra-large-only">
                                            {this.state.auditedPeople.map((auditedPerson, i) =>
                                                <CollectionItem>
                                                    <TextInput s={12} m={12} value={auditedPerson.lastName}
                                                               label="Nom" validate
                                                               onChange={(event) => this.changeLastName(event, i)}/>
                                                    <TextInput s={12} m={12} value={auditedPerson.firstName}
                                                               label="Prénom" validate
                                                               onChange={(event) => this.changeFirstName(event, i)}/>
                                                    <TextInput s={12} m={12} value={auditedPerson.function}
                                                               label="Fonction" validate
                                                               onChange={(event) => this.changeFunction(event, i)}/>
                                                    <Checkbox checked={auditedPerson.openingMeeting}
                                                              label="Réunion d'ouverture"
                                                              onChange={(event) => this.changeOpeningMeeting(event, i)}/><br/>
                                                    <Checkbox checked={auditedPerson.onSiteAudit} label="Audit sur site"
                                                              onChange={(event) => this.changeOnSiteAudit(event, i)}/><br/>
                                                    <Checkbox checked={auditedPerson.closingMeeting}
                                                              label="Réunion de clôture"
                                                              onChange={(event) => this.changeClosingMeeting(event, i)}/><br/>
                                                    <Button flat waves="light" className="red-text right"
                                                            onClick={() => this.delAuditedPeople(i)}><Icon>clear</Icon></Button>
                                                </CollectionItem>
                                            )}
                                        </Collection>
                                        <Button waves="light" className="blue left"
                                                onClick={() => this.addAuditedPeople()}><Icon>add</Icon>
                                        </Button>
                                    </Row>
                                </Col>
                            </CollectionItem>
                        </Collection>
                    </Col>
                </Row>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateAuditedPeople()}/>
                    }

                </Button>
            </div>
        );
    }
}

export default AuditedPeopleEdit;
