import React, {Component} from 'react';
import './Organizations.css';
import OrganizationsList from "../../components/OrganizationsList/OrganizationsList";
import FabAddPage from "../../components/FabAddPage/FabAddPage";
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();

class Organizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            cookie: jwt.decode(cookies.get('token'))
        };
    }

    render() {
        return (
            <div>
                <OrganizationsList/>
                {this.state.cookie && this.state.cookie.isAdmin === 1 &&
                <FabAddPage link="/addOrganization"/>
                }
            </div>
        );
    }
}

export default Organizations;
