import React, {Component} from 'react';
import {Tab, Tabs} from 'react-materialize';
import AuditorEditPersonalInfo from "../AuditorEditPersonalInfo/AuditorEditPersonalInfo";
import AuditorEditPermissions from "../AuditorEditPermissions/AuditorEditPermissions";

class AuditorEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 0,
                    name: "Informations personnelles",
                    disabled: false
                },
                {
                    id: 1,
                    name: "Permissions",
                    disabled: false
                }
            ]
        };
    }

    componentDidMount() {
    }

    disableTabs = (activeTabId) => {
        this.setState(state => {
            const tabs = state.tabs.map((tab, i) => {
                if (i !== activeTabId) {
                    return {
                        ...tab,
                        disabled: true
                    };
                } else {
                    return tab;
                }
            });

            return {
                tabs
            };
        });
    };

    enableTabs = () => {
        this.setState(state => {
            const tabs = state.tabs.map((tab, i) => {
                return {
                    ...tab,
                    disabled: false
                };
            });
            return {
                tabs
            };
        });
    };

    render() {
        return (
            <div>
                <Tabs className="tab-demo tabs-fixed-width z-depth-1">
                    <Tab className="" title={this.state.tabs[0].name} disabled={this.state.tabs[0].disabled} active>
                        <AuditorEditPersonalInfo id={this.props.id} disableTabs={this.disableTabs}
                                                 enableTabs={this.enableTabs}/>
                    </Tab>
                    <Tab className="" title={this.state.tabs[1].name} disabled={this.state.tabs[1].disabled}>
                        <AuditorEditPermissions id={this.props.id} disableTabs={this.disableTabs}
                                                enableTabs={this.enableTabs}/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default AuditorEdit;
