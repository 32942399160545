import React, {Component} from 'react';
import './Auditors.css';
import AuditorsList from "../../components/AuditorsList/AuditorsList";
import FabAddPage from "../../components/FabAddPage/FabAddPage";
import {createBrowserHistory} from "history";
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const history = createBrowserHistory({
    forceRefresh: true
});
const cookies = new Cookies();

class Auditors extends Component {
    render() {
        if (jwt.decode(cookies.get('token')).isAdmin !== 1)
            history.push('/');
        return (
            <div>
                <AuditorsList/>
                <FabAddPage link="/addAuditor"/>
            </div>
        );
    }
}

export default Auditors;
