import React, {Component} from 'react';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    TextInput,
    Select,
    Button,
    DatePicker
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class CertificationDecision extends Component {
    constructor(props) {
        super(props);
        this.state = {
            certificationDecision: {},
            status: [],
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getCertificationDecision();
        fetch('/server/status')
            .then(res => res.json())
            .then(status =>
                this.setState({status}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(7);
        }
    }

    getCertificationDecision() {
        const {id} = this.props.id;
        fetch(`/server/reports/${id}/certificationDecision`)
            .then(res => res.json())
            .then(certificationDecision =>
                this.setState({certificationDecision}));
    }

    changeStatus(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            certificationDecision: {
                ...this.state.certificationDecision,
                fk_status: event.target.value
            }
        });
    }

    changeAuthor(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            certificationDecision: {
                ...this.state.certificationDecision,
                author: event.target.value
            }
        });
    }

    changeDecisionDate(date) {
        this.checkDataAlreadyChanged();

        this.setState({
            certificationDecision: {
                ...this.state.certificationDecision,
                decisionDate: new Date(date)
            }
        });
    }

    updateCertificationDecision() {
        const {id} = this.props.id;
        fetch(`/server/reports/update/${id}/certificationDecision/`, {
            method: 'POST',
            body: JSON.stringify(this.state.certificationDecision),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });

        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
    }

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getCertificationDecision();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col m={12} s={12}>
                        <Collection className="z-depth-1">
                            <CollectionItem>
                                <Select className="select-collection" label="Status"
                                        value={this.state.certificationDecision.fk_status} s={12} m={12}
                                        onChange={(event) => this.changeStatus(event)}>
                                    {this.state.status.map((stat, i) =>
                                        <option value={stat.pk_status}>
                                            {stat.name}
                                        </option>
                                    )}
                                </Select>
                                <TextInput s={12} m={12} value={this.state.certificationDecision.author}
                                           label="Auteur" validate
                                           onChange={(event) => this.changeAuthor(event)}/>
                                <DatePicker s={12} m={12}
                                            options={{
                                                defaultDate: new Date(this.state.certificationDecision.decisionDate),
                                                setDefaultDate: true,
                                                format: 'dd/mm/yyyy'
                                            }}
                                            value={new Date(this.state.certificationDecision.decisionDate).toLocaleDateString()}
                                            label="Date"
                                            icon="date_range"
                                            onChange={(event) => this.changeDecisionDate(event)}/>
                            </CollectionItem>
                        </Collection>
                    </Col>
                </Row>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateCertificationDecision()}/>
                    }

                </Button>
            </div>
        );
    }
}

export default CertificationDecision;
