import React, {Component} from "react";
import {Switch, Route, BrowserRouter} from "react-router-dom";
import Home from "../screens/Home/Home";
import Auditors from "../screens/Auditors/Auditors";
import Auditor from "../screens/Auditor/Auditor";
import AddAuditor from "../screens/AddAuditor/AddAuditor";
import Organizations from "../screens/Organizations/Organizations";
import Organization from "../screens/Organization/Organization";
import AddOrganization from "../screens/AddOrganization/AddOrganization";
import Checklists from "../screens/Checklists/Checklists";
import Checklist from "../screens/Checklist/Checklist";
import AddChecklist from "../screens/AddChecklist/AddChecklist";
import Report from "../screens/Report/Report";
import AddReport from "../screens/AddReport/AddReport";
import Login from "../screens/Login/Login";
import withAuth from "./withAuth";
import Settings from "../screens/Settings/Settings";
import OrganizationUser from "../screens/OrganizationUser/OrganizationUser";
import AddOrganizationUser from "../screens/AddOrganizationUser/AddOrganizationUser";
import ReportPdfViewer from "../screens/ReportPdfViewer/ReportPdfViewer";

export default class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={withAuth(Home)}/>
                    <Route path='/auditors' component={withAuth(Auditors)}/>
                    <Route path='/auditor/:id' component={withAuth(Auditor)}/>
                    <Route path='/addAuditor' component={withAuth(AddAuditor)}/>
                    <Route path='/organizations' component={withAuth(Organizations)}/>
                    <Route path='/organization/:id' component={withAuth(Organization)}/>
                    <Route path='/addOrganization' component={withAuth(AddOrganization)}/>
                    <Route path='/checklists' component={withAuth(Checklists)}/>
                    <Route path='/checklist/:id' component={withAuth(Checklist)}/>
                    <Route path='/addChecklist' component={withAuth(AddChecklist)}/>
                    <Route path='/addReport' component={withAuth(AddReport)}/>
                    <Route path='/report/:id' component={withAuth(Report)}/>
                    <Route path='/settings' component={withAuth(Settings)}/>
                    <Route path='/organizationUser/:id' component={withAuth(OrganizationUser)}/>
                    <Route path='/addOrganizationUser/:id' component={withAuth(AddOrganizationUser)}/>
                    <Route path='/reportPdfViewer/:id' component={withAuth(ReportPdfViewer)}/>
                    <Route path='/login' component={Login}/>
                    <Route component={Home}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
