import React, {Component} from 'react';
import {Card, Tab, Tabs} from 'react-materialize';
import './LoginForm.css';
import {createBrowserHistory} from "history";
import LoginFormAuditors from "../LoginFormAuditors/LoginFormAuditors";
import LoginFormOrganizations from "../LoginFormOrganizations/LoginFormOrganizations";

const history = createBrowserHistory({
    forceRefresh: true
});
const sha256 = require('sha256');

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {}
        };
    }

    changeUsername(event) {
        this.setState({
            user: {
                ...this.state.user,
                username: event.target.value
            }
        });
    }

    changePassword(event) {
        this.setState({
            user: {
                ...this.state.user,
                password: event.target.value
            }
        });
    }

    login(event) {
        event.preventDefault();
        fetch('/server/login', {
            method: 'POST',
            body: JSON.stringify({
                username: this.state.user.username,
                password: sha256(this.state.user.password)
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    history.replace('/');
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
                console.error(err);
                alert('Error logging in please try again');
            });
    }

    render() {
        return (
            <div>
                <Card header={<h4 className="login-header">DataCert</h4>} s={12} m={6} className="login-form">
                    <Tabs className="tab-demo tabs-fixed-width z-depth-1">
                        <Tab className="" title="Espace auditeurs" active>
                            <LoginFormAuditors/>
                        </Tab>
                        <Tab className="" title="Espace organisations">
                            <LoginFormOrganizations/>
                        </Tab>
                    </Tabs>
                </Card>

            </div>
        );
    }
}

export default LoginForm;
