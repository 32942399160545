import React, {Component} from 'react';
import {Button, Card, TextInput} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";

class NewOrganizationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organization: {}
        }
    }

    componentDidMount() {
    }

    changeName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                name: event.target.value
            }
        });
    };

    changeEmail(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                email: event.target.value
            }
        });
    };

    changePhone(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                phone: event.target.value
            }
        });
    };

    changeContactLastName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                contactLastName: event.target.value
            }
        });
    }

    changeContactFirstName(event) {
        this.setState({
            organization: {
                ...this.state.organization,
                contactFirstName: event.target.value
            }
        });
    }

    addOrganization() {
        fetch('/server/organizations/add', {
            method: 'PUT',
            body: JSON.stringify(this.state.organization),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.created) {
                    this.setState({
                        organization: {}
                    });
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            })
    };

    render() {
        return (
            <div>
                <Card>
                    <TextInput label="Nom Organisation" value={this.state.organization.name} validate required
                               onChange={(event) => this.changeName(event)}/>
                    <TextInput label="Email" value={this.state.organization.email} email validate required
                               onChange={(event) => this.changeEmail(event)}/>
                    < TextInput label="Téléphone" value={this.state.organization.phone} validate required
                                onChange={(event) => this.changePhone(event)}/>
                    <TextInput label="Nom Contact" value={this.state.organization.contactLastName} validate required
                               onChange={(event) => this.changeContactLastName(event)}/>
                    <TextInput label="Prénom Contact" value={this.state.organization.contactFirstName} validate required
                               onChange={(event) => this.changeContactFirstName(event)}/>
                    <Button
                        floating
                        fab
                        icon="save"
                        className="green"
                        large
                        wave="light"
                        tooltip="Ajouter"
                        tooltipOptions={{position: 'left'}}
                        onClick={() => this.addOrganization()}
                    >
                    </Button>
                </Card>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default NewOrganizationForm;
