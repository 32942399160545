import React, {Component} from 'react';
import {
    TextInput,
    Button,
    Col,
    Row,
    Select,
    Checkbox,
    CollectionItem,
    Collection
} from 'react-materialize';
import {toast, ToastContainer} from "react-toastify";
import {Redirect} from 'react-router-dom';

class AuditorEditPersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditor: {},
            languages: [],
            message: {},
            fireRedirect: false,
            urlParam: '',
            dataChanged: false
        };
    }

    componentDidMount() {
        this.getAuditor();
        fetch('/server/languages')
            .then(res => res.json())
            .then(languages => {
                this.setState({languages});
            });
    }

    getAuditor() {
        const {id} = this.props.id;
        fetch(`/server/auditors/auditor/${id}`)
            .then(res => res.json())
            .then(auditor =>
                this.setState({auditor}));
    }

    checkDataAlreadyChanged() {
        if (!this.state.dataChanged) {
            this.setState({
                dataChanged: true
            });
            this.props.disableTabs(0);
        }
    }

    changeLastName(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            auditor: {
                ...this.state.auditor,
                lastName: event.target.value
            }
        });
    };

    changeFirstName(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            auditor: {
                ...this.state.auditor,
                firstName: event.target.value
            }
        });
    };

    changeEmail(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            auditor: {
                ...this.state.auditor,
                email: event.target.value
            }
        });
    };

    changePhone(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            auditor: {
                ...this.state.auditor,
                phone: event.target.value
            }
        });
    };

    changeIsAdmin(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            auditor: {
                ...this.state.auditor,
                isAdmin: event.target.checked
            }
        });
    }

    changeLanguage(event) {
        this.checkDataAlreadyChanged();

        this.setState({
            auditor: {
                ...this.state.auditor,
                fk_language: event.target.value
            }
        });
    }

    updateAuditor() {
        const {id} = this.props.id;
        fetch(`/server/auditors/update/${id}`, {
            method: 'POST',
            body: JSON.stringify(this.state.auditor),
            headers: {"Content-Type": "application/json"}
        })
            .then((response) => {
                return response.json()
            })
            .then((message) => {
                this.setState({message});
            })
            .then(() => {
                if (this.state.message.updated) {
                    toast.success(this.state.message.msg);
                } else {
                    toast.error(this.state.message.msg);
                }
            });

        this.setState({
            dataChanged: false
        });
        this.props.enableTabs()
    };

    cancelUpdate() {
        this.setState({
            dataChanged: false
        });
        this.props.enableTabs();
        this.getAuditor();
    }

    render() {
        return (
            <div>
                <Collection className="z-depth-1">
                    <CollectionItem>
                        <TextInput s={12} m={12} value={this.state.auditor.lastName} label="Nom"
                                   onChange={(event) => this.changeLastName(event)}/>
                        <TextInput s={12} m={12} value={this.state.auditor.firstName} label="Prénom"
                                   onChange={(event) => this.changeFirstName(event)}/>
                        < TextInput s={12} m={12} value={this.state.auditor.email} label="Email" email validate
                                    onChange={(event) => this.changeEmail(event)}/>
                        <TextInput s={12} m={12} value={this.state.auditor.phone} label="Téléphone"
                                   onChange={(event) => this.changePhone(event)}/>
                        <Row>
                            <Col>
                                <Checkbox checked={this.state.auditor.isAdmin} label="Administrateur"
                                          onChange={(event) => this.changeIsAdmin(event)}/>
                            </Col>
                        </Row>
                        <Select className="select-collection" label="Langue"
                                value={this.state.auditor.fk_language} s={12} m={12}
                                onChange={(event) => this.changeLanguage(event)}>
                            {this.state.languages.map((language, i) =>
                                <option value={language.pk_language}>
                                    {language.language}
                                </option>
                            )}
                        </Select>
                    </CollectionItem>
                </Collection>
                <Button
                    floating
                    fab
                    icon="edit"
                    className="blue"
                    large
                    disabled={!this.state.dataChanged}
                >
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="cancel" className="red" tooltip="Annuler modifications"
                            tooltipOptions={{position: 'left'}} onClick={() => this.cancelUpdate()}/>
                    }
                    {this.state.dataChanged &&
                    <Button waves="light" floating icon="save" className="green" tooltip="Sauvegarder"
                            tooltipOptions={{position: 'left'}} onClick={() => this.updateAuditor()}/>
                    }
                </Button>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
                {this.state.fireRedirect && (
                    <Redirect to={`/auditors?${this.state.urlParam}`}/>
                )}
            </div>
        );
    }
}

export default AuditorEditPersonalInfo;
