import React, {Component} from 'react';
import {Button, Card, TextInput} from 'react-materialize';
import {ToastContainer, toast} from 'react-toastify';
import Cookies from "universal-cookie";

const jwt = require('jsonwebtoken');
const cookies = new Cookies();
const sha256 = require('sha256');

class SettingsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: jwt.decode(cookies.get('token')),
            password: {
                actualPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
        }
    }

    changeActualPassword(event) {
        this.setState({
            password: {
                ...this.state.password,
                actualPassword: event.target.value
            }
        });
    }

    changeNewPassword(event) {
        this.setState({
            password: {
                ...this.state.password,
                newPassword: event.target.value
            }
        });
    }

    changeConfirmNewPassword(event) {
        this.setState({
            password: {
                ...this.state.password,
                confirmNewPassword: event.target.value
            }
        });
    }

    updatePassword() {
        if (this.state.password.newPassword !== this.state.password.confirmNewPassword) {
            toast.error('Le nouveau mot de passe n\'a pas été confirmé correctement');
        } else if (this.state.password.newPassword === this.state.password.actualPassword) {
            toast.error('Le nouveau mot de passe ne peut pas être identique à l\'ancien');
        } else {
            if (this.state.cookie.pk_auditor) {
                console.log('AUDITOR');
                fetch(`/server/auditors/update/password/${this.state.cookie.pk_auditor}/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        actualPassword: sha256(this.state.password.actualPassword),
                        newPassword: sha256(this.state.password.newPassword),
                        confirmNewPassword: sha256(this.state.password.confirmNewPassword)
                    }),
                    headers: {"Content-Type": "application/json"}
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((message) => {
                        this.setState({message});
                    })
                    .then(() => {
                        if (this.state.message.updated) {
                            toast.success(this.state.message.msg);
                        } else {
                            toast.error(this.state.message.msg);
                        }
                    });
            }else if (this.state.cookie.pk_organizationUser) {
                console.log('USER');
                fetch(`/server/organizationUser/update/password/${this.state.cookie.pk_organizationUser}/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        actualPassword: sha256(this.state.password.actualPassword),
                        newPassword: sha256(this.state.password.newPassword),
                        confirmNewPassword: sha256(this.state.password.confirmNewPassword)
                    }),
                    headers: {"Content-Type": "application/json"}
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((message) => {
                        this.setState({message});
                    })
                    .then(() => {
                        if (this.state.message.updated) {
                            toast.success(this.state.message.msg);
                        } else {
                            toast.error(this.state.message.msg);
                        }
                    });
            }else{
                console.log('ELSE')
            }
        }
    }

    render() {
        return (
            <div>
                <Card>
                    <TextInput value={this.state.password.actualPassword} label="Mot de passe actuel"
                               onChange={(event) => this.changeActualPassword(event)} password/>
                    <TextInput value={this.state.password.newPassword} label="Nouveau mot de passe"
                               onChange={(event) => this.changeNewPassword(event)} password/>
                    <TextInput value={this.state.password.confirmNewPassword} label="Confirmer nouveau mot de passe"
                               onChange={(event) => this.changeConfirmNewPassword(event)} password/>
                    <Button className="green" onClick={() => this.updatePassword()}>Sauvegarder</Button>
                </Card>
                <ToastContainer position="bottom-center" hideProgressBar="true"/>
            </div>
        );
    }
}

export default SettingsEdit;
