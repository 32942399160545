import React, { Component } from 'react'
import {
  Row,
  Col,
  Collection,
  CollectionItem,
  TextInput,
  Select,
  Icon,
  Button,
  Textarea,
  Checkbox
} from 'react-materialize'
import { toast, ToastContainer } from 'react-toastify'
import InputFiles from 'react-input-files'
import axios from 'axios'

class AuditReportEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      auditReports: [],
      standards: [],
      interpretations: [],
      files: [],
      dataChanged: false,
      // docFilePath: 'http://localhost:5000', // dev
      docFilePath: 'https://datacert.ch' // prod
    }
  }

  componentDidMount () {
    this.getAudiReports()
    fetch('/server/standards')
    .then(res => res.json())
    .then(standards =>
      this.setState({standards}))
    fetch('/server/interpretations')
    .then(res => res.json())
    .then(interpretations =>
      this.setState({interpretations}))
  }

  getAudiReports () {
    const {id} = this.props.id
    fetch(`/server/reports/${id}/auditReports`)
    .then(res => res.json())
    .then(auditReports =>
      this.setState({auditReports}))
  }

  checkDataAlreadyChanged () {
    if (!this.state.dataChanged) {
      this.setState({
        dataChanged: true
      })
      this.props.disableTabs(5)
    }
  }

  addAuditReport () {
    this.checkDataAlreadyChanged()

    const {id} = this.props.id
    this.setState(state => {
      const auditReports = state.auditReports.concat({
        chapter: '',
        description: '',
        noncomplianceDelayTreatment: '',
        actionPlan: '',
        actionPlanValidation: '',
        APVVvalidated: 0,
        correctiveActionMade: '',
        attachment: '',
        correctiveActionValidation: '',
        CAVValidated: 0,
        fk_standard: 1,
        fk_interpretation: 1,
        fk_report: id
      })

      return {
        auditReports
      }
    })
  }

  delAuditReport (i) {
    this.checkDataAlreadyChanged()
    this.setState(state => {
      const auditReports = state.auditReports.filter((item, j) => i !== j)
      return {
        auditReports
      }
    })
  }

  changeStandard (event, i) {
    this.checkDataAlreadyChanged()

    const newStandard = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            fk_standard: newStandard
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeInterpretation (event, i) {
    this.checkDataAlreadyChanged()

    const newInterpretation = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            fk_interpretation: newInterpretation
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeDescription (event, i) {
    this.checkDataAlreadyChanged()

    const newDescription = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            description: newDescription
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeChapter (event, i) {
    this.checkDataAlreadyChanged()

    const newChapter = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            chapter: newChapter
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeNoncomplianceDelayTreatment (event, i) {
    this.checkDataAlreadyChanged()

    const newNoncomplianceDelayTreatment = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            noncomplianceDelayTreatment: newNoncomplianceDelayTreatment
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeActionPlan (event, i) {
    this.checkDataAlreadyChanged()

    const newActionPlan = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            actionPlan: newActionPlan
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeActionPlanValidation (event, i) {
    this.checkDataAlreadyChanged()

    const newActionPlanValidation = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            actionPlanValidation: newActionPlanValidation
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeAPVVvalidated (event, i) {
    this.checkDataAlreadyChanged()

    const newAPVVvalidated = event.target.checked
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            APVVvalidated: newAPVVvalidated
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeCorrectiveActionMade (event, i) {
    this.checkDataAlreadyChanged()

    const newCorrectiveActionMade = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            correctiveActionMade: newCorrectiveActionMade
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeAttachment (event, i) {
    this.checkDataAlreadyChanged()

    const file = event[0]
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            attachment: file.name
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
    this.setState(state => {
      const files = state.files.concat({
        file
      })

      return {
        files
      }
    })
  }

  changeCorrectiveActionValidation (event, i) {
    this.checkDataAlreadyChanged()

    const newCorrectiveActionValidation = event.target.value
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            correctiveActionValidation: newCorrectiveActionValidation
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  changeCAVValidated (event, i) {
    this.checkDataAlreadyChanged()

    const newCAVValidated = event.target.checked
    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            CAVValidated: newCAVValidated
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  delAttachment (i) {
    this.checkDataAlreadyChanged()

    this.setState(state => {
      const auditReports = state.auditReports.map((item, j) => {
        if (j === i) {
          return {
            ...item,
            attachment: null
          }
        } else {
          return item
        }
      })

      return {
        auditReports
      }
    })
  }

  updateAuditReport () {
    const {id} = this.props.id
    fetch(`/server/reports/update/${id}/auditReports/`, {
      method: 'POST',
      body: JSON.stringify(this.state.auditReports),
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
      return response.json()
    })
    .then((message) => {
      this.setState({message})
    })
    .then(() => {
      if (this.state.message.updated) {
        toast.success(this.state.message.msg)
      } else {
        toast.error(this.state.message.msg)
      }
    })
    this.state.files.map((file) => {
      const data = new FormData()
      data.append('file', file.file)
      console.log(file.file, data)
      axios.post('/server/files/upload', data)
      .then(res => {
        console.log(res.statusText)
      })
    })
    this.setState({
      dataChanged: false
    })
    this.props.enableTabs()
  }

  cancelUpdate () {
    this.setState({
      dataChanged: false
    })
    this.props.enableTabs()
    this.getAudiReports()
  }

  render () {
    return (
      <div>
        <Row>
          <Col m={12}
               s={12}>
            <Collection className="z-depth-1">
              <CollectionItem>
                <Col m={12}
                     s={12}>
                  <Row>
                    <Button waves="light"
                            className="blue left"
                            onClick={() => this.addAuditReport()}><Icon>add</Icon>
                    </Button>
                  </Row>
                  <Row>
                    {this.state.auditReports.map((auditReport, i) =>
                      <Collection className="z-depth-1">
                        <CollectionItem>
                          <Button flat
                                  waves="light"
                                  className="red-text right"
                                  onClick={() => this.delAuditReport(i)}><Icon>clear</Icon></Button>
                          <Select className="select-collection"
                                  label="Norme"
                                  value={auditReport.fk_standard}
                                  s={12}
                                  m={12}
                                  onChange={(event) => this.changeStandard(event, i)}>
                            {this.state.standards.map((standard, i) =>
                              <option value={standard.pk_standard}>
                                {standard.name}
                              </option>
                            )}
                          </Select>
                          <Select className="select-collection"
                                  label="Interprétation"
                                  value={auditReport.fk_interpretation}
                                  s={12}
                                  m={12}
                                  onChange={(event) => this.changeInterpretation(event, i)}>
                            {this.state.interpretations.map((interpretation, i) =>
                              <option value={interpretation.pk_interpretation}>
                                {interpretation.name}
                              </option>
                            )}
                          </Select>
                          <TextInput s={12}
                                     m={12}
                                     l={12}
                                     xl={12}
                                     value={auditReport.chapter}
                                     label="Chapitre"
                                     validate
                                     onChange={(event) => this.changeChapter(event, i)} />
                          <Textarea s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    value={auditReport.description}
                                    label="Description"
                                    onChange={(event) => this.changeDescription(event, i)} />
                          {(auditReport.fk_interpretation === 3 || auditReport.fk_interpretation === 4
                            || auditReport.fk_interpretation === '3' || auditReport.fk_interpretation === '4') &&
                          <div>
                            <Row>
                              <Col s={12}
                                   m={12}
                                   l={12}
                                   xl={12}>
                                <TextInput s={12}
                                           m={12}
                                           l={12}
                                           xl={12}
                                           value={auditReport.noncomplianceDelayTreatment}
                                           label="Délai pour le traitement de la Non-conformité"
                                           validate
                                           onChange={(event) => this.changeNoncomplianceDelayTreatment(event, i)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col s={12}
                                   m={12}
                                   l={12}
                                   xl={12}>
                                <TextInput s={12}
                                           m={12}
                                           l={12}
                                           xl={12}
                                           value={auditReport.actionPlan}
                                           label="Plan d’action (analyse de la cause et action corrective planifiée)"
                                           validate
                                           onChange={(event) => this.changeActionPlan(event, i)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col s={10}
                                   m={10}
                                   l={10}
                                   xl={10}>
                                <TextInput s={12}
                                           m={12}
                                           l={12}
                                           xl={12}
                                           value={auditReport.actionPlanValidation}
                                           label="Validation du plan d’action par l’auditeur"
                                           validate
                                           onChange={(event) => this.changeActionPlanValidation(event, i)} />
                              </Col>
                              <Col s={2}
                                   m={2}
                                   l={2}
                                   xl={2}>
                                <Checkbox checked={auditReport.APVVvalidated}
                                          label="Validé"
                                          onChange={(event) => this.changeAPVVvalidated(event, i)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col s={12}
                                   m={12}
                                   l={12}
                                   xl={12}>
                                <TextInput s={12}
                                           m={12}
                                           l={12}
                                           xl={12}
                                           value={auditReport.correctiveActionMade}
                                           label="Action corrective réalisée"
                                           validate
                                           onChange={(event) => this.changeCorrectiveActionMade(event, i)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col s={10}
                                   m={10}
                                   l={10}
                                   xl={10}>
                                <TextInput s={12}
                                           m={12}
                                           l={12}
                                           xl={12}
                                           value={auditReport.correctiveActionValidation}
                                           label="Validation de l'action corrective par l'auditeur"
                                           validate
                                           onChange={(event) => this.changeCorrectiveActionValidation(event, i)} />
                              </Col>
                              <Col s={2}
                                   m={2}
                                   l={2}
                                   xl={2}>
                                <Checkbox checked={auditReport.CAVValidated}
                                          label="Validé"
                                          onChange={(event) => this.changeCAVValidated(event, i)} />
                              </Col>
                            </Row>
                            <Row>
                              <Col s={12}
                                   m={12}
                                   l={12}
                                   xl={12}>
                                {(auditReport.attachment === null || auditReport.attachment === '') ? (
                                  <td className="table-document">
                                    <InputFiles onChange={(event) => this.changeAttachment(event, i)}
                                                accept="*">
                                      <Button className="blue upload-button"><Icon>file_upload</Icon> Télécharger</Button>
                                    </InputFiles>

                                  </td>
                                ) : (
                                  <td className="table-document">
                                    <a href={`${this.state.docFilePath}/${auditReport.attachment}`}
                                       target="_blank"
                                       rel="noopener noreferrer"><Icon>attach_file</Icon> {auditReport.attachment}</a>
                                    <Button flat
                                            waves="light"
                                            className="red-text left"
                                            onClick={() => this.delAttachment(i)}><Icon>delete</Icon></Button>
                                  </td>
                                )}
                              </Col>
                            </Row>
                          </div>
                          }
                        </CollectionItem>
                      </Collection>
                    )}
                  </Row>
                </Col>
              </CollectionItem>
            </Collection>
          </Col>
        </Row>
        <Button floating
                fab
                icon="edit"
                className="blue"
                large
                disabled={!this.state.dataChanged}>
          {this.state.dataChanged &&
          <Button waves="light"
                  floating
                  icon="cancel"
                  className="red"
                  tooltip="Annuler modifications"
                  tooltipOptions={{position: 'left'}}
                  onClick={() => this.cancelUpdate()} />
          }
          {this.state.dataChanged &&
          <Button waves="light"
                  floating
                  icon="save"
                  className="green"
                  tooltip="Sauvegarder"
                  tooltipOptions={{position: 'left'}}
                  onClick={() => this.updateAuditReport()} />
          }

        </Button>
      </div>
    )
  }
}

export default AuditReportEdit
